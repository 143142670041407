import React, { useState } from "react";
import { useTranslation } from "react-i18next";

type Props = {};

function About({}: Props) {
  const { t } = useTranslation();
  const [isShowMore, setIsShowMore] = useState(false);
  const toggleReadMoreLess = () => {
    setIsShowMore(!isShowMore);
  };

  return (
    <div className="stopscrollmargin mx-auto py-14 max-[927px]:flex-col-reverse   text-center md:text-start max-w-7xl md:py-10 flex flex-col md:flex-row items-center justify-around gap-5">
      {/* text */}
      <div className="text-wrap md:w-1/2 px-4"  data-aos="fade-right" data-aos-duration="1000"  >
        <h1 className="text-yellow-500 text-base md:text-lg font-normal font-Acme uppercase leading-normal tracking-wide">
          {t("What is trust ai ecosysteam?.")}
        </h1>

        <div className="pt-5 md:pt-10 text-zinc-100 mb-2 text-2xl md:text-4xl font-normal font-Syne uppercase">
          {t("Introduction")}
        </div>

        <div className="flex flex-col gap-5 md:gap-10 md:mt-5 text-wrap text-zinc-100 tracking-wide text-base md:text-lg font-light leading-normal">
          <p className="">
            {t(
              "The emergence of artificial intelligence has significantly impacted multiple facets of our daily existence, with the cryptocurrency market being no exception. By seamlessly amalgamating AI with market dynamics, we can unlock a plethora of advantages and streamline the intricate demands it entails. From streamlining the process of contract creation and validating non-fungible tokens (NFTs) to optimizing trade executions through automated swap platforms, AI infuses operational efficiency and user convenience."
            )}
          </p>
          {isShowMore && (
            <>
              <p>
                {t(
                  "Furthermore, a commendable 30% of the generated revenue will be equitably distributed to our esteemed token holders at the inception of each month, synchronized with the operational cadence of all associated functionalities."
                )}
              </p>
              <p>
                {t(
                  "Additionally, in a pursuit of unwavering transparency and credibility, a dedicated wallet will earmark the aforementioned 30% of revenue. This wallet will be made accessible to the public, underscoring our commitment to upholding the utmost levels of accountability."
                )}
              </p>
            </>
          )}
          <button onClick={toggleReadMoreLess} className="text-[#DAA200]">
            {isShowMore ? "Read Less" : "Read More"}
          </button>
        </div>
      </div>

      {/* image */}
      <div className="flex justify-center md:w-1/2"  data-aos="fade-left" data-aos-duration="1000" > 
        <img
          className="mx-auto w-full md:max-w-[512px] max-h-[512px]"
          src="/gif/about.gif"
          alt="About GIF"
        />
      </div>
      {/* image */}
    </div>
  );
}

export default About;
