import React from "react";
import {
  service_manu,
  resource_manu,
  community_manu,
  social_manu,
} from "../../config/index";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Languagelist from "../language/Languagelist";
type Props = {};

function Fotter({}: Props) {
  const {t} = useTranslation()
  return (
    <div className="pt-10 md:pt-[100px]  bg-[#000000] relative">
      <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-4 xl:md:grid-cols-4 py-5 px-6  gap-20">
        {/* about */}
        <div className="md:col-span-2 flex flex-col gap-3">
          <div className="flex flex-row items-center gap-3">
            <img src="/logo.png" className="w-[60px]" />
            <div>
              <span className="text-white text-4xl tracking-[1px] font-bold  leading-normal">
                Trust{" "}
              </span>
              <span className="text-[#E7AA00]  text-4xl racking-[1px] font-bold  leading-normal">
                AI
              </span>
            </div>
          </div>
          <div className="max-w-[380px] pt-1  text-zinc-100 text-lg font-normal  leading-normal">
           {t("fotter_about")}
          </div>

      
          <div className="flex flex-row items-center gap-3 mt-2 py-3">
            {social_manu.map((e, indx) => {
              return (
                <a key={indx} href={e.link} target="_blank" rel="noopener noreferrer" >
                  <img className="w-[30px]" src={`/social/${e.name}.png`} alt="" />
                </a>
              );
            })}
             <div className="flex items-center bg-[#1b1b1b] rounded-full ">
             <a href="mailto:contact@trust-ai.io" target="_blank" rel="noopener noreferrer" >
                  <img className="w-[30px]" src={`/social/MAIL.png`} alt="" />
                </a>
             </div>
          </div>
          <Languagelist/>
        </div>
        {/* about */}

        {/* Services */}
        <div className="mt-2 md:mt-10">
          <div className="text-[#E7AA00] font-bold text-base  uppercase leading-[18px]">
            {t("Services")}
          </div>
          <ul className="flex flex-col gap-5 mt-6 ">
            {service_manu?.map((e, index) => {
              const isActive = true;

              return (
                <li key={index} className="">
                  {e.islink ? (
                    <a
                      href={e?.link}
                      target="_blank"
                      rel="noreferrer"
                      className={`header_link_li flex flex-row gap-3 items-center  ${
                        isActive ? "" : ""
                      }`}
                    >
                      {t(e?.name)}
                      {e.comingsoon && (
                        <div className="max-w-[95px] flex items-center h-6 px-2.5 bg-gradient-to-b from-[#3d3205] to-[#3e3006] rounded-lg justify-start   ">
                          <div className="text-yellow-500 text-[12px] mt-[-2px] ">
                            Coming Soon
                          </div>
                        </div>
                      )}
                    </a>
                  ) : (
                    <Link
                      to={e?.link}
                      className="flex flex-row gap-3 items-center"
                    >
                      <p className={`header_link_li ${isActive ? "" : ""}`}>
                      {t(e?.name)}
                      </p>
                      {e.comingsoon && (
                        <div className="max-w-[95px] flex items-center h-6 px-2.5 bg-gradient-to-b from-[#3d3205] to-[#3e3006] rounded-lg justify-start   ">
                          <div className="text-yellow-500 text-[12px] mt-[-2px] ">
                            {t("Coming Soon")}
                          </div>
                        </div>
                      )}
                    </Link>
                  )}
                </li>
              );
            })}
          </ul>
        </div>
        {/* Services */}

        {/* Resources */}
        <div className="mt-2 md:mt-10">
          <div className="text-[#E7AA00] text-base  font-bold  uppercase leading-[18px]">
                {t("Resources")}
          </div>
          <ul className="flex flex-col gap-5 mt-6">
            {resource_manu?.map((e, index) => {
              const isActive = true;

              return (
                <li key={index}>
                  {e.islink ? (
                    <a
                      href={e?.link}
                      target="_blank"
                      rel="noreferrer"
                      className={`header_link_li ${isActive ? "" : ""}`}
                    >
                   {t(e?.name)}
                    </a>
                  ) : (
                    <Link to={e?.link}>
                      <p className={`header_link_li ${isActive ? "" : ""}`}>
                      {t(e?.name)}
                      </p>
                    </Link>
                  )}
                </li>
              );
            })}
          </ul>
        </div>
        {/* Resources */}

        {/* Community */}
        {/* <div className="mt-2 md:mt-10">
          <div className="text-white text-sm font-normal uppercase leading-[18px]">
          {t("Community")}
          </div>
          <ul className="flex flex-col gap-3 mt-4">
            {community_manu?.map((e, index) => {
              const isActive = true;

              return (
                <li key={index}>
                  {e.islink ? (
                    <a
                      href={e?.link}
                      target="_blank"
                      rel="noreferrer"
                      className={`header_link_li ${isActive ? "" : ""}`}
                    >
                   {t(e?.name)}
                    </a>
                  ) : (
                    <Link to={e?.link}>
                      <p className={`header_link_li ${isActive ? "" : ""}`}>
                      {t(e?.name)}
                      </p>
                    </Link>
                  )}
                </li>
              );
            })}
          </ul>
        </div> */}
        {/* Community */}
      </div>
      <div className="opacity-80 text-center text-violet-100 text-sm font-normal  leading-normal py-6 border-t mt-6 border-gray-400 border-opacity-15">
        Copyright © 2024 Trust AI. All Rights Reserved.
      </div>
    </div>
  );
}

export default Fotter;
