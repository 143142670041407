import React from "react";
import ImageTextComponent from "../Home/Helper/card/ImageTextComponent";
import { useTranslation } from "react-i18next";
type Props = {};

function Tracking({}: Props) {
  const {t} = useTranslation()
  return (
    <div className='stopscrollmargin relative py-[100px]'>
    <img src="/Image/Vector12.png" className="absolute bottom-[166%] z-1 right-[-50%]  w-[100vw] h-[100vh]"/>
    <div className="max-w-7xl mx-auto  p-5 md:p-10">
      <div data-aos="fade-up" data-aos-easing="ease-in-out" data-aos-duration="2000" className=" text-center text-yellow-500 text-sm font-normal  leading-snug">
        CMC & CG
      </div>
      <div data-aos="fade-up" data-aos-easing="ease-in-out" data-aos-duration="2000" className="pt-3 md:pt-5 text-center text-zinc-100 mb-2 text-2xl md:text-4xl font-normal font-Syne uppercase">
     {t("Tracking Our Token.")}
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-10 pt-10">
        <ImageTextComponent
          imageUrl="/Image/CMC.png"
          text1="Coinmarketcap"
          text2={t("Read more")}
          link="https://coinmarketcap.com/currencies/trust-ai/"
          bgColor="#2B6AFF"
        />
        <ImageTextComponent
          imageUrl="/Image/coingecko.png"
          text1="Coingecko"
          text2={t("Read more")}
          link="https://www.coingecko.com/en/coins/trust-ai"
          bgColor="#8BC53F"
        />
      </div>
      </div>
    </div>
  );
}

export default Tracking;
