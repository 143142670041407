import React from "react";
import Label from "./Label";
type Props = {
  name: string;
  value: any;
  label: string;
  handlevaluechange: (value: any) => void;
  placeholder:string,
  loading:boolean
};

function InputSt({ name, value, label, handlevaluechange,placeholder,loading }: Props) {
  return (
    <div className="flex flex-col ">
    
        <Label name={label} />
    
      <div className="relative w-full flex items-center">
        <input
          type="text"
          title="Only numeric values are allowed."
          pattern="^[0-9]*(\.[0-9]*)?$"
          placeholder={placeholder}
          className="py-2.5 text-sm md:text-base text-[#898990] bg-inputbg rounded-[5px] border-2 border-zinc-500  forced-colors:bg-clip-border  focus:ring-yellow-500 focus:border-yellow-500    w-[100%] h-[64px] "
          value={value}
          onChange={handlevaluechange}
          name={name}
          disabled={loading}
        />
      </div>
    </div>
  );
}

export default InputSt;
