import React, { useEffect } from "react";
import MyListbox from ".";
import { useTranslation } from "react-i18next";
type Props = {};
const data_type = [
  { name: "English", value: "en" },
  { name: "Arabic", value: "ar" },
  {
    name: "French",
    value: "fr",
  },
  {
    name: "Chinese",
    value: "ch",
  },
];

function Languagelist({}: Props) {
  const [value, setvalue] = React.useState(data_type[0]);
  const { t } = useTranslation();
  const { i18n } = useTranslation();

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };
  useEffect(() => {
    changeLanguage(value.value);
  }, [value]);
  const HandleChange = (value: any) => {
    setvalue(value);
  };
  return (
    <div>
      <MyListbox
        data_type={data_type}
        selected={value}
        handleoptionchange={HandleChange}
      />
    </div>
  );
}

export default Languagelist;
