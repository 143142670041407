import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import {
  getTokeninstance,
  getContractInstanceSigner,
  ConvertEthTonormal,
  getTokeninstanceBysigner,
} from "../utils/contracthelper";

//buy contract  - contractaddress

const useStakeTransation = (
  signer: any,
  account: any,
  count: any,
  stakecontract: string,
  staketoken: string,
  ABI: any,
  stakingTokenDecimals: number,
  rewardTokenDecimals: number
) => {
  const [loading, setSellTokenLoading] = useState(false);
  const [balance, setbalance] = useState("0");
  const [approve, setapprove] = useState(false);

  // check approval amount for user

  const CheckApproval = async () => {
    try {
      setSellTokenLoading(true);

      const totalAmount = Number(count);
      const myContract = await getTokeninstanceBysigner(staketoken, signer);

      const currentAllowance = await myContract.allowance(
        account,
        stakecontract
      );

      const getAmount = await ConvertEthTonormal(
        currentAllowance,
        stakingTokenDecimals
      );

      if (parseInt(getAmount) > 0 && parseInt(getAmount) >= totalAmount) {
        setapprove(true);
      } else {
        setapprove(false);
      }
      setSellTokenLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  // check user balance

  const CheckBalance = async () => {
    try {
      setSellTokenLoading(true);
      const myContract = await getTokeninstanceBysigner(staketoken, signer);
      const currentBalance = await myContract.balanceOf(account);
      
    const userBalance = await ConvertEthTonormal(
        currentBalance,
        stakingTokenDecimals
      );
      setbalance(userBalance);
      setSellTokenLoading(false);
    } catch (error) {
      console.log(error);
      setSellTokenLoading(false);
    }
  };

  useEffect(() => {
    if (account !=undefined && signer!=undefined) {
      CheckApproval();
 
    }
  }, [signer, account, count]);

  useEffect(() => {
    console.log(signer);
    
    if (account !=undefined && signer!=undefined) {
      CheckBalance();
    }

    
  }, [account, signer]);

  //run func for allowance token  to contract
  const SetapproveToken = async (fname: string, args: Array<any>) => {
    if (!count) return;
    const name = String(fname);
    setSellTokenLoading(true);
    //coming from hook
    const myContract = await getTokeninstance(staketoken, true, 56, signer);
    try {
      //  const gasprice =await myContract.estimateGas?.[name](...args,{value: (ethers.utils.parseUnits(Amount))});
      const response = await myContract?.[name](...args);
      const receipt = await response.wait();
      toast.success("Successfully Approved");
      CheckApproval();
      setSellTokenLoading(false);
      return { isDone: true };
    } catch (error: any) {
      if (error.code == "ACTION_REJECTED") {
        toast.error("User Cancelled The Transaction");
      } else {
        toast.error(error.reason || "Something went wrong try again");
      }

      setSellTokenLoading(false);
      console.log(error);
      return { isDone: false };

      //failed
    }
  };

  // run buy func .
  const HandleRun = async (
    fname: string,
    args: Array<any>,
    type: string,
    count: number
  ) => {
    const name = String(fname);
    setSellTokenLoading(true);

    //coming from hook
    const myContract = await getContractInstanceSigner(
      stakecontract,
      ABI,
      signer
    );
    try {
      //  const gasprice =await myContract.estimateGas?.[name](...args,{value: (ethers.utils.parseUnits(Amount))});
      const response = await myContract?.[name](...args);
      const receipt = await response.wait();

      toast.success(`Congratulations! ${type}`);

      setSellTokenLoading(false);
      return { isDone: true };
    } catch (error: any) {
      if (error.code == "ACTION_REJECTED") {
        toast.error("User Cancelled The Transaction");
      } else {
        toast.error(error.reason || "Something went wrong try again");
      }

      setSellTokenLoading(false);
      console.log(error);
      return { isDone: false };

      //failed
    }
  };

  return { loading, HandleRun, SetapproveToken, approve, balance };
};

export default useStakeTransation;
