import React, { useEffect } from "react";
import { pools, Pool } from "../../config/Pool";
import { Header } from "../../components/Header/Header";
import Topprice from "../../components/Header/Topprice";
import { getALLpool } from "../../data/pool/getPool";
import { useAppSelector, useAppdispatch } from "../../hooks/redux";
import Poolbox from "../../components/Staking/pool/Poolbox";
import { useAccount } from "wagmi";
import Fotter from "../../components/newFotter/Fotter";
type Props = {};

function Stakenew({}: Props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const dispatch = useAppdispatch();
  const { address } = useAccount();

  const { pools:poolss } = useAppSelector((state) => state.pool);

  useEffect(() => {
   
  if(poolss.length == 0){
    dispatch(
        getALLpool({
          user: address,
        })
      );
    
  }
        

  }, [address]);
  return (
    <div className="bg-black font-ChakraPetch bg-cover bg-center custom-bg bg-fixed  ">

      <div className="absolute right-0 top-0">
        <img className="w-[100vw] h-[100vh]" src="/homegradient.png" />
      </div>
      <div className=" m-auto w-full relative min-h-screen border-x border-[#111]">
        <div className="flex flex-wrap justify-center gap-5 m-auto w-full pb-36 pt-24 max-w-7xl">
          {pools?.map((e: Pool, indx: any) => {
            return <Poolbox data={e} key={indx} indx={indx} />;
          })}
        </div>
      </div>

    </div>
  );
}

export default Stakenew;
