import React from "react";

type Props = {
  name: string;
};

function Label({ name }: Props) {
  return (
    <div className="py-2">
      <span className="text-white text-sm md:text-xl font-bold font-Syne leading-[50px]">
        {name}{" "}
      </span>
      <span className="text-red-600 text-sm md:text-xl font-bold font-Syne leading-[50px]">
        *
      </span>
    </div>
  );
}

export default Label;
