export const Tagslide = [
    "Backlight",
    "Color Grading",
    "Depth of Field",
    "TXAA",
    "Beautiful",
    "Moody Lighting",
    "Backlight",
    "Color Grading",
    "Depth of Field",
    "TXAA",
    "Beautiful",
    "Moody Lighting"



]
export const tag_search = [
    "Carton",
    "Street Art",
    "Splatter Pain",
    "Neon"
]

export const TRT_TOKEN  = "0x50c7584be73d20760f1947ffcbf7942822c968c8"
export const MIN_TOKEN_HOLD= 500;
export const MINT_CONTRACT: { [key: number]: string } = {
    97: "",
    80001:"0xd0E51F8019cdeF7FFeD31B6c4FE189Dc54614fE7",
    56:"0x37994E392AE867824C06F2c360ddb5F6DB0F531D",
    137:"0xe91d2eB9BAb2883c41579b25568F34A8B1328D1d",
    42161:"0xde8707fdddba8144840e2d3f08b0f51cd3007ac4",
    250:"0xde8707fDdDBa8144840e2d3F08b0f51cD3007AC4",
    10:"0x7857277dc4dc0249742f4ec9cae031529abce4db",
    1:"0x3fce2840fd6cdb7ca49a9349296e39c64cdf5ab5"
};
export const MINT_COST: { [key: number]: string } = {
    97: "3311039004039468",
    80001:"50000000000",
    56:"3229452607782981",
    1:"411003382557838",
    137:"1194203812376250600",
    250:"2679212525854400600",
    42161:"411003382557838",
    10:"411003382557838"
};
export const PICTURE_COST: { [key: number]: string } = {
    97: "",
    80001:"50000000000",
    56:"331103900403946",
    1:"44037343667429",
    137:"127278929227824200",
    250:"287727556531271700",
    42161:"44037343667429",
    10:"44037343667429"

};

export const NATIVE_TOKEN: { [key: number]: string } = {
    97: "bnb",
    80001:"matic",
    56:"bnb",
    137:"matic",
    250:"ftm",
    10:"eth",
    1:"eth",
    42161:"eth"

};


export const SysteamBoxContent = [
    {
        title:"Set up a digital Wallet",
        logo:"wallet",
        full:"Set up a digital wallet compatible with the blockchain network used by the NFT´s."
    },
    {
        title:"Generate NFT",
        logo:"Nft",
        full:"Discover or generate NFT by using our AI in the Website."
    },
    {
        title:"Mint The NFT",
        logo:"NftMint",
        full:"Start minting the NFT´s by following´s instructions."
    },
    {
        title:"Access The Manage",
        logo:"MobileAccess",
        full:"Access and manage your NFT´s in your wallet or consider transferring them to a more secure wallet if desired"
    }
]


export const FeaturesContent = [
    {
        title:"AI  Art Generation",
        logo:"fees",
        full:"Create unique digital assets using AI."
    }, {
        title:"Validator",
        logo:"Validator",
        full:"These NFT´s service as validators for transaction on TRUST Ecosystem."
    },
    {
        title:"Binance Blockchain ",
        logo:"binancedex",
        full:"Seamlessly mint and trad NFTs on Binance blockchain."
    },
]


export const Topnftcontent = [
    {
        logo:"1",
    },
    {
        logo:"2",
    },
    {
        logo:"3",
    }
]