import { ToastContainer, toast } from "react-toastify";

import globalService from "../service/TrtService";

export const SendverifyContract = async (
  contractAddressDeploy: string,
  arg: any,
  contractCode: string,
  address: string,
  chain: number,
  Tokenindex: number,
  tokenInfo: any,
  Admin: any,
  setopenTokendeploy: (value: boolean) => void
) => {
  // we will call verify api..
  const data = {
    tokenType: Tokenindex,
    constructorArguments: arg,
    contractaddress: contractAddressDeploy,
    sourceCode: contractCode,
    chainId: chain,
    contractName: tokenInfo.symbol,
  };
  const response = await toast.promise(globalService.sendVerifyRequest(data), {
    pending:
      "Verification in progress. This may take a moment. Please do not close the window....",
    success: "Contract verified successfully!",
    error:
      "Something went wrong! Our API will automatically re-verify after a few minutes.",
  });

  if (response.data.success) {
    setopenTokendeploy(false);
  }
};
