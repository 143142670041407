import React from "react";
import { Roadmap as Data } from "../../config/index";
import { useTranslation } from "react-i18next";
type Props = {};

function Roadmap({}: Props) {
  const { t } = useTranslation();
  return (
    <div id="roadmap" className="stopscrollmargin  " >
      <div className="timeline">
        <div className="myContainer">
          <div className="title-container">
            <p>{t("Our Roadmap")}</p>
            <h1>{t("startegy & project plan")}</h1>
          </div>

          <div className="timeline-row">
            <div className="detail-row">
              {/* <!-- TimeLine Item  --> */}
              <div className="timeline-content timeline-content1"  >
                <img
                  className="mobile-checkBar mobile-checkBar1 checkBarDone"
                  src="/Checkdone.svg"
                  alt="icon"
                />
                <div className="mobile-border1"></div>
                <div className="timeline-title">
                <p>{t("Phase 1")}</p>
                  <h3>{t("start TRUST AI")}</h3>
                </div>
                <div className="timeline-item" >
                  <img className="checkBar checkBarDone" src="/Checkdone.svg" alt="icon" />
                  <div className="detail">
                    <ul>
                      {Data[0].data.map((e, indx) => {
                        return (
                          <li
                            className={`${e.isDone ? "list-none" : ""}`}
                            key={indx}
                          >
                           <p>
                             <span className="text-[#49E102]"> {e.isDone ? "✔ " : ""}</span> {t(e.name)}
                            </p>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>

              {/* <!-- TimeLine Item  --> */}
              <div className="timeline-content timeline-content2">
                <img
                  className="mobile-checkBar mobile-checkBar2 checkBarDone"
                  src="/Checkdone.svg"
                  alt="icon"
                />
                <div className="mobile-border"></div>
                <div className="timeline-title">
                <p>{t("Phase 2")}</p>
                  <h3>TRUST AI</h3>
                </div>
                <div className="timeline-item" >
                  <img className="checkBar checkBarDone" src="/Checkdone.svg" alt="icon" />
                  <div className="detail">
                    <ul>
                      {Data[1].data.map((e, indx) => {
                        return (
                          <li
                            className={`${e.isDone ? "list-none" : ""}`}
                            key={indx}
                          >
                           <p>
                             <span className="text-[#49E102]"> {e.isDone ? "✔ " : ""}</span> {t(e.name)}
                            </p>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>

              {/* <!-- TimeLine Item  --> */}
              <div className="timeline-content timeline-content3">
                <img
                  className="mobile-checkBar checkBarDone mobile-checkBar3"
                  src="/Checkdone.svg"
                  alt="icon"
                />
                <div className="timeline-title">
                  <p>{t("Phase 3")}</p>
                  <h3>{t("TRUST AI")}</h3>
                </div>
                <div className="timeline-item  right-border">
                  <img className="checkBar checkBarDone" src="/Checkdone.svg" alt="icon" />
                  <div className="detail">
                    <ul>
                      {Data[2].data.map((e, indx) => {
                        return (
                          <li
                            className={`${e.isDone ? "list-none" : ""}`}
                            key={indx}
                          >
                            <p>
                             <span className="text-[#49E102]"> {e.isDone ? "✔ " : ""}</span> {t(e.name)}
                            </p>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="timeline-row2">
            <div className="detail-row2">
              {/* <!-- TimeLine Item  --> */}
              <div className="timeline-content timeline-content4">
                <img
                  className="mobile-checkBar mobile-checkBar4"
                  src="/checkIcon.svg"
                  alt="icon"
                />
                <div className="timeline-title">
                  <p>{t("Phase 4")}</p>
                  <h3>TRUST AI</h3>
                </div>
                <div className="timeline-item2  left-border">
                  <img className="checkBar" src="/checkIcon.svg" alt="icon" />
                  <div className="detail">
                    <ul>
                      {Data[3].data.map((e, indx) => {
                        return (
                          <li
                            className={`${e.isDone ? "list-none" : ""}`}
                            key={indx}
                          >
                           <p>
                             <span className="text-[#49E102]"> {e.isDone ? "✔ " : ""}</span> {t(e.name)}
                            </p>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>

              {/* <!-- TimeLine Item  --> */}
              <div className="timeline-content timeline-content5">
                <img
                  className="mobile-checkBar mobile-checkBar5"
                  src="/checkIcon.svg"
                  alt="icon"
                />
                <div className="timeline-title">
                  <p>{t("Phase 5")}</p>
                  <h3>TRUST AI</h3>
                </div>
                <div className="timeline-item2 ">
                  <img className="checkBar" src="/checkIcon.svg" alt="icon" />
                  <div className="detail">
                    <ul>
                      {Data[4].data.map((e, indx) => {
                        return (
                          <li
                            className={`${e.isDone ? "list-none" : ""}`}
                            key={indx}
                          >
                         <p>
                             <span className="text-[#49E102]"> {e.isDone ? "✔ " : ""}</span> {t(e.name)}
                            </p>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>

              {/* <!-- TimeLine Item  --> */}
              <div className="timeline-content timeline-content6">
                <img
                  className="mobile-checkBar mobile-checkBar6"
                  src="/checkIcon.svg"
                  alt="icon"
                />
                <img
                  className="mobile-checkBar mobile-checkBar7"
                  src="/checkIcon.svg"
                  alt="icon"
                />
                <div className="mobile-border2"></div>
                <div className="timeline-title title-border">
                  <p>{t("Phase 6")}</p>
                  <h3>TRUST AI</h3>
                </div>
                <div className=" timeline-item2  m-border">
                  <img className="checkBar" src="/checkIcon.svg" alt="icon" />
                  <div className="detail">
                    <ul>
                      {Data[5].data.map((e, indx) => {
                        return (
                          <li
                            className={`${e.isDone ? "list-none" : ""}`}
                            key={indx}
                          >
                         <p>
                             <span className="text-[#49E102]"> {e.isDone ? "✔ " : ""}</span> {t(e.name)}
                            </p>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Roadmap;
