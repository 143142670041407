import React from "react";
import NFTminterhelper from "./NFTminterhelper";

type Props = {};

function NFTminter({}: Props) {
  return (
    <div className="py-10 px-4"     id="nftminter">
      <div className=" text-center text-white text-2xl md:text-5xl font-bold font-['Syne'] pb-10">
        Generate NFT With AI
      </div>
      <NFTminterhelper />
    </div>
  );
}

export default NFTminter;
