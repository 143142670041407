// src/services/productService.js
import newRequest from "../utils/API";
import axios from "axios";

const globalService = {
  sendVerifyRequest: (data: any) => {
    return newRequest.post(`/global/verifyContract`, data);
  },
  getCode: (data: any) => {
    return newRequest.post(`/global/genarate`, data);
  },
  routeswap: (data: any, config: any) => {
    return newRequest.post(`/global/getRouter`, data);
  },
  getPrice: () => {
    return newRequest.get("/global/priceHistoryTest");
  },
  login: (info: any) => {
    return newRequest.post("/user/createuser", info);
  },
  getuser: () => {
    return newRequest.get("/user/getUser");
  },
  logotUser: () => {
    return newRequest.get("/user/logout");
  },
  nonce:()=>{
    return newRequest.get("/user/nonce")
  },
  createImage:(data:any)=>{
    return newRequest.post("/user/createImage",data)
  },
  storenft:(data:any)=>{
    return newRequest.post("/user/storenft",data)
  },
  checkfreelimit:()=>{
    return newRequest.get("/user/checkfreelimit")
  }
};

export default globalService;
