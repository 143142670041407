// pool abi
import FixedStake01 from "../abi/FixedStake01.json";
export const endpoint = 'https://api.studio.thegraph.com/query/44397/trustaistaking/v0.0.1'; // Replace with your actual GraphQL endpoint
export const CHAIN_ID_STAKING = 56
export enum PoolType {
  fixedLock,
  Dynamic,
}

export interface subPool {
  index: number;
  apy: number;
  lockDays: number;
}

export interface Pool {
  name: string;
  header: string;
  stakename: string;
  earnname: string;
  rate?: any;
  staketoken: string;
  totaldeposit?: any;
  unclaimed?: string;
  staked?: number;
  poolloading: boolean;
  ABI?: any;
  contract: string;
  stakeFunc: string;
  unstakeFunc: string;
  earnlogosrc?: string;
  poolType: PoolType;
  stakingTokenDecimals:number,
  rewardTokenDecimals:number,
  subpool?: subPool[];
  stakingTokenBalance:string,
  user:string | undefined
}

export const pools: Pool[] = [
  {
    name: "SPUNK",
    header: "",
    stakename: "TRT",
    earnname: "TRT",
    earnlogosrc: "spunk",
    rate: "0",
    staketoken: "0x50c7584be73d20760f1947ffcbf7942822c968c8",
    totaldeposit: 0,
    staked: 0,
    unclaimed: "0",
    poolloading: true,
    ABI: FixedStake01,
    contract: "0x80685511b726790049660c2A4E1cF3A1F7ef03d3",
    stakeFunc: "stake",
    unstakeFunc: "EndStake",
    poolType: PoolType.fixedLock,
    stakingTokenDecimals:18,
    rewardTokenDecimals:18,
    stakingTokenBalance:"0",
    user:"",
    subpool: [
      {
        index: 0,
        apy: 3,
        lockDays: 30,
      },
      {
        index: 1,
        apy: 10,
        lockDays: 90
      },
      {
        index: 2,
        apy: 25,
        lockDays: 180,
      },
    ],
  },
];
