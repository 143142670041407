import React from "react";
import { Partner } from "../../config/index";

import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import Marquee from "react-fast-marquee";

type Props = {};

function Hero({}: Props) {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });

  const isTablet = useMediaQuery({
    query: "(min-width: 768px) and (max-width: 1223px)",
  });

  const { t } = useTranslation();

  return (
    <div
      id="home"
      className="min-h-screen flex items-center justify-center  bg-cover bg-center custom-bg relative py-10 md:pt-[200px]   "
    >
      <div className="absolute right-0 top-0">
        <img className="w-[100vw] h-[100vh]" src="/homegradient.png" />
      </div>
      {/* main text */}

      <div className="pt-[150px] z-10  max-[350px]:max-w-[300px] max-[767px]:max-w-[360px]" >
        <div className=" space-y-5  " >
          <h1 data-aos="fade-up" data-aos-duration="1000"  className="text-3xl font-Syne textShineSlow   md:text-[90px] md:leading-[100px] text-white text-center font-bold">
            {t("An ecosystem built on the Binance Blockchain")}
          </h1>
          <p data-aos="fade-up" data-aos-duration="1000"  className="text-xl text-white text-center font-semibold ">
            {t("Create Anything - AI With No Restrictions.")}
          </p>

          <div data-aos="fade-up" data-aos-duration="1000" className="flex flex-wrap items-end max-[400px]:gap-0 gap-3 max-[400px]:max-w-[321px] max-w-[400px] mx-auto">
            <a
              href="https://github.com/The-Matrix-Labs/SafuProjects/blob/main/TrustAi.pdf"
              rel="noopener noreferrer"
              target="_blank"
              className=" w-[80px] rounded-[5px] bg-violet-600 mx-auto justify-center  md:w-[124px] px-4 md:px-[30px] py-[15px] items-center gap-2 flex"
            >
              <div className="text-center text-white text-sm md:text-lg font-bold font-['Syne']">
                {t("SAFU")}
              </div>
            </a>
            <a
              href="https://github.com/Coinsult/solidity/blob/main/Coinsult_Trust_AI_0x13...c8d7_Audit.pdf"
              rel="noopener noreferrer"
              target="_blank"
              className=" w-[80px] rounded-[5px] bg-violet-600 mx-auto justify-center md:w-[124px]  px-4 md:px-[30px] py-[15px] items-center gap-2 flex"
            >
              <div className="text-center text-white text-sm md:text-lg font-bold font-['Syne']">
                      {t("AUDIT")}
              </div>
            </a>
            <a
              href="https://pinksale.notion.site/Trust-AI-KYC-Verification-c8bad34403e542fe8add4a740006913c"
              rel="noopener noreferrer"
              target="_blank"
              className=" w-[80px] rounded-[5px] bg-violet-600 mx-auto justify-center md:w-[124px] px-4 md:px-[30px]  py-[15px] items-center gap-2 flex"
            >
              <div className="text-center text-white text-sm md:text-lg font-bold font-['Syne']">
                       {t("KYC")}
              </div>
            </a>
          </div>

          {/* <div className="grid grid-cols-2 md:flex md:flex-wrap justify-center gap-y-10 gap-x-5 md:gap-[50px] pt-[175px] items-center px-5 ">
            {Partner.map((e, indx) => {
              return (
                <img
                  style={{
                    maxHeight: `${e.h}px`,
                    height: "fit",
                  }}
                  key={indx}
                  src={`/icon/${e.name}.png`}
                  className="mx-auto"
                />
              );
            })}
          </div> */}
          <div className="pt-[50px] md:pt-[175px] relative w-[100vw]">
            <Marquee
              direction="right"
              autoFill={true}
              pauseOnHover={isDesktopOrLaptop ? true : false}
              play={true}
              pauseOnClick={true}
              speed={15}
            >
              {Partner.map((e, indx) => (
                <div key={indx} className="max-w-[210px] px-5">
                  <a href={e.link} target="_blank" rel="noopener noreferrer">
                    <img
                      style={{
                        maxHeight: `${e.h}px`,
                        height: "fit",
                      }}
                      src={`/icon/${e.name}.png`}
                      alt={e.name}
                      className="mx-auto"
                    />
                  </a>
                </div>
              ))}
            </Marquee>
          </div>
        </div>
      </div>

      {/* main text */}
    </div>
  );
}

export default Hero;
