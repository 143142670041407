import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useTranslation } from "react-i18next";
type Props = {
  title: string;
  logo: string;
  dec: string;
  index: number;
};

function Featuresbox({ title, logo, dec, index }: Props) {
  const { t } = useTranslation();
  return (
    <div
      style={{

      }}
      className={`flex-col w-[90vw] mx-auto md:max-w-[328px] h-fit relative box_style_nft py-3 border-2 border-white border-opacity-20 rounded-3xl items-center justify-start px-5 ${
        index == 1 ? " min-[1210px]:box_style_nft_layers" : " z-[10]"
      }`}
    >
      <div className=" text-yellow-500 text-3xl md:text-4xl font-extrabold ">
        0{index + 1}.
      </div>
      {/* {index == 1 && (
        <LazyLoadImage
          src="/Nft/box/left.png"
          className="absolute left-[-85px] z-[0] w-[85px]"
        />
      )} */}
      <div className="flex-col  items-start gap-4 flex flex-1 justify-end pt-3">
        <div className=" text-white text-xl md:text-2xl font-bold font-['Syne'] leading-[38px]">
          {t(title)}
        </div>
        <div className="pr-3 opacity-80 leading-9 text-neutral-100 text-sm md:text-base font-normal font-['Inter']">
          {t(dec)}
        </div>
      </div>

      <div className="pb-5 flex justify-end">
        <LazyLoadImage className="max-w-[60px] md:max-w-[70px]" src={`/Nft/box/${logo}.png`} />
      </div>
    </div>
  );
}

export default Featuresbox;
