import React, { useEffect } from "react";
import {
  unixToDate,
  unixToFullDate,
  formateHexday,
  unixToHTime,
} from "../../utils/date";
import { Fragment, useState } from "react";

import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import {
  useTransformedPriceData,
  useTransformedPriceAgoData,
} from "../../hooks/chart";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Rectangle,
  ResponsiveContainer,
  AreaChart,
  Area,
} from "recharts";

type Props = {
  data: any;
  title: string;
  hoverlabel: string;
  timestampKey: string;
  valueKey: string;
};

interface DataPoint {
  time: string;
  price: number;
}

interface CustomTooltipProps {
  active?: boolean;
  payload?: any[];
  label?: string;
}

function Linechart({ data, title, hoverlabel, timestampKey, valueKey }: Props) {
  const chartData = data;

  const CustomTooltip: React.FC<CustomTooltipProps> = ({
    active,
    payload,
    label,
  }) => {
    if (active && payload && payload.length) {
      const dataPoint: DataPoint = payload[0].payload as DataPoint;

      return (
        <div className="custom-tooltip bg-[#f0f0f0] dark:bg-slate-800 px-2 py-2 rounded-lg">
          <p>{`$${Number(dataPoint?.price).toFixed(4)}`}</p>
          <p className="text-[10px]">{` ${unixToFullDate(
            Math.floor(Number(label))
          )}`}</p>
        </div>
      );
    }

    return null;
  };
  const CustomXAxisTick = (props: any) => {
    // Customize the tick text as needed
    const formattedText = props.payload.value;

    return (
      <text
        className="text-[10px]"
        x={props.x}
        y={props.y}
        dy={16}
        textAnchor="middle"
        fill="#667D94"
      >
        {unixToDate(Math.floor(formattedText))}
      </text>
    );
  };

  return (
    <div className="md:h-[500px] w-full   phone:p-4 p-2  md:p-5 rounded-2xl aspect-square my-5 ">
      <div className=" items-center pb-10">
        <h1 className="pt-5 p-8 md:pt-10 text-start text-yellow-500  mb-2 text-2xl font-Acme md:text-4xl font-normal   ">
          {title}
        </h1>
      </div>
      <ResponsiveContainer aspect={0} width="99%" height="93%" minWidth="0">
        {chartData ? (
          <AreaChart
            data={chartData}
            margin={{
              top: 5,
              right: 20,
              left: -5,
              bottom: 10,
            }}
          >
            <XAxis
              dataKey={timestampKey}
              strokeOpacity={0}
              className="text-[12px]"
              tick={<CustomXAxisTick />}
            />
            <YAxis
              domain={["auto", "auto"]}
              axisLine={false}
              tickLine={false}
              className="text-[10px]"
              scale="linear"
            />
            <defs>
              <linearGradient id="colorView" x1="0" y1="0" x2="0" y2="1">
                <stop offset="30%" stopColor="#eed300" stopOpacity={0.4} />
                <stop offset="75%" stopColor="#D9A200" stopOpacity={0.1} />
                <stop offset="95%" stopColor="#D9A200" stopOpacity={0.3} />
                {/* <stop offset="50%" stopColor="#D9A200" stopOpacity={0.1} /> */}
              </linearGradient>
            </defs>

            <Tooltip content={<CustomTooltip />} />
            {/* <CartesianGrid opacity={0.1} vertical={false} /> */}
            <CartesianGrid
              strokeDasharray="4 4"
              stroke="#8884d8"
              opacity={0.1}
              vertical={false}
            />
            <Area
              type="monotone"
              dot={false}
              dataKey={valueKey}
              radius={300}
              stroke="#D9A200"
              strokeWidth={1}
              strokeOpacity={1}
              // strikethroughThickness={10}
              // activeDot={{
              //   width: 0,
              // }}
              // fill="#4e3c08"
              fill="url(#colorView)"
              style={{
                // filter: "drop-shadow(2px 2px 6px  #D9A200)",
                // strokeLinecap: "round",
              }}
            />
          </AreaChart>
        ) : (
          <div>Loading chart..</div>
        )}
      </ResponsiveContainer>
    </div>
  );
}

export default Linechart;
