import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import {
  getTokeninstance,
  getContractInstanceSigner,
  ConvertEthTonormal,

} from "../utils/contracthelper";

//buy contract  - contractaddress

const useEnd = (
  signer: any,
  account: any,
  stakecontract: string,
  staketoken: string,
  ABI: any,
  stakingTokenDecimals: number,
) => {
  const [loading, setSellTokenLoading] = useState(false);
  const [balance, setbalance] = useState("0");
  // check user balance

  const CheckBalance = async () => {
    try{
      const myContract = await getTokeninstance(staketoken, true, 56, signer);
      const currentBalance = await myContract.balanceOf(account);
      const userBalance = await ConvertEthTonormal(
        currentBalance,
        stakingTokenDecimals
      );
      setbalance(userBalance);
    }catch{

    }
    // setSellTokenLoading(true);

    // setSellTokenLoading(false);
  };

  useEffect(() => {
   if(account){
    CheckBalance();
   }
  }, [account]);

  // run buy func .
  const HandleRun = async (
    fname: string,
    args: Array<any>,
    type: string,
    count: number
  ) => {
 
    const name = String(fname);
    setSellTokenLoading(true);

    //coming from hook
    const myContract = await getContractInstanceSigner(
      stakecontract,
      ABI,
      signer
    );
    console.log("pad2");
    try {
      //  const gasprice =await myContract.estimateGas?.[name](...args,{value: (ethers.utils.parseUnits(Amount))});
      const response = await myContract?.[name](...args);
      const receipt = await response.wait();

      toast.success(`Congratulations! ${type}`);

      setSellTokenLoading(false);
      return { isDone: true };
    } catch (error: any) {
      if (error.code == "ACTION_REJECTED") {
        toast.error("User Cancelled The Transaction");
      } else {
        toast.error(error.reason || "Something went wrong try again");
      }

      setSellTokenLoading(false);
      console.log(error);
      return { isDone: false };

      //failed
    }
  };

  return { loading, HandleRun, balance };
};

export default useEnd;
