import React from "react";
import Systeambox from "../Home/Helper/Systeambox";
import { SysteamBoxContent } from "../../../config/nftmint/index";
type Props = {};

function Systeam({}: Props) {
  return (
    <div className="w-full md:max-w-7xl mx-auto py-24">
      <div className=" text-center text-white text-2xl md:text-5xl font-bold font-['Syne']">
        How it works?
      </div>
      <div className="flex flex-wrap md:grid md:grid-cols-2 lg:grid-cols-4 justify-between pt-10 md:pt-24 gap-14 ">
        {SysteamBoxContent.map((e, indx) => {
          return <Systeambox key={indx} index={indx} title={e.title} logo={e.logo} dec={e.full} />;
        })}
      </div>
    </div>
  );
}

export default Systeam;