export const HeaderList = [
    {
        name: "Home",
        link: "https://www.trust-ai.io/",
        islink: true,
        isDivlink:false
      },
      {
        name: "TRT Blockchain",
        link: "https://trtscan.com/",
        islink: true,
        isDivlink:false
      },
      {
        name: "TRT Launchpad",
        link: "https://www.trtlaunch.io/",
        islink: true,
        isDivlink:false
      },
      {
        name: "DEX",
        link: "/swap",
        islink: false,
        isDivlink:false
      },
      {
        name: "TRT Wallet",
        link: "https://trust-ai.io/coming-soon.html",
        islink: true,
        isDivlink:false
      },
      {
        name: "Create Token",
        link: "/createTokenmaker",
        islink: false,
        isDivlink:false
      },
    
      // {
      //   name: "StakingV1",
      //   link: "/staking",
      //   islink: false,
      //   isDivlink:false
      // },
      {
        name: "NFT AI",
        link: "/nftai",
        islink: false,
        isDivlink:true
      }, 
      // {
      //   name: "Bridge",
      //   link: "/bridge",
      //   islink: false,
      //   isDivlink:false
      // }
      
      {
        
        name: "Tokenomics",
        link: "https://www.trust-ai.io/#tokenomics",
        islink: true,
        isDivlink:false
      },
      {
        name: "FAQ",
        link: "https://www.trust-ai.io/#faq",
        islink: true,
        isDivlink:false
      },  
            // {
      //  name:"Feed",
      //  link: "/Feed",
      //  islink: true,
      //  route:false
      // },
    
]


