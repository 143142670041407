import React, { FC } from "react";
import { ConnectButton, useConnectModal } from "@rainbow-me/rainbowkit";
import ScaleLoader from "react-spinners/ScaleLoader";

interface GenerateButtonProps {
  onClickGenerate: () => void;
  onClickSignin: () => void;
  signin: boolean;
  connetwallet: boolean;
  loading: boolean;
}

const GenerateButton: FC<GenerateButtonProps> = ({
  onClickGenerate,
  onClickSignin,
  signin,
  connetwallet,
  loading,
}) => {
  const { openConnectModal } = useConnectModal();
  return (
    <div className="flex space-x-4">
      {connetwallet ? (
        signin ? (
          <button
           disabled={loading}
            onClick={onClickGenerate}
            className="sButton text-xs md:text-base  px-2 md:px-10 rounded-xl rounded-l-none cursor-pointer w-fit font-bold font-Aleo text-neutral-900"
          >
            {!loading && "Generate"}
            <ScaleLoader
              height={20}
              loading={loading}
              color="#ffffff"
              className="text-white"
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </button>
        ) : (
          <button
          disabled={loading}
            onClick={onClickSignin}
            className="sButton text-xs md:text-base  px-2 md:px-10 rounded-xl rounded-l-none cursor-pointer w-fit font-bold font-Aleo text-neutral-900"
          >
            {!loading && "Signin"}
            <ScaleLoader
              height={20}
              loading={loading}
              color="#ffffff"
              className="text-white"
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </button>
        )
      ) : (
        <button
          onClick={openConnectModal}
          className="sButton text-xs md:text-base  px-2 md:px-10 rounded-xl rounded-l-none cursor-pointer w-fit font-bold font-Aleo text-neutral-900"
        >
          Connect Wallet
        </button>
      )}
    </div>
  );
};

export default GenerateButton;
