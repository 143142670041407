import React, { useState } from "react";
import { Token_Info } from "../../config/index";
import { useTranslation } from "react-i18next";
import { BUY_LINK } from "../../config/index";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";

type Props = {};

function TokenInfo({}: Props) {
  const { t } = useTranslation();
  const [contract, setcontract] = useState(
    "0x50C7584be73D20760f1947fFcbF7942822C968C8"
  );

  const handleCopy = () => {
    toast.success("contract address copied to clipboard!");
  };

  return (
    <div className="relative stopscrollmargin">
      <img
        src="/layer/Vector14.png"
        className="absolute bottom-[0%] left-[-15%] w-[100vw] z-0 h-[100vh] opacity-60"
      />
      <div className="max-w-7xl mx-auto p-10 z-10 relative  " data-aos="fade-up" data-aos-duration="3000">
        <div className="flex flex-col justify-center md:grid md:grid-cols-2 max-w-[822px] gap-5 mx-auto">
          {/* 1st div  */}
          <div>
            <p className="text-yellow-500 text-base font-normal  uppercase leading-normal tracking-wide mb-7">
              {t("About token")}
            </p>
            <div className="max-w-[270px]  text-zinc-100 text-4xl font-bold font-Syne uppercase leading-[42.64px]">
              {t("Trust ai")}
            </div>
            <div className="max-w-[346px] mt-2  text-zinc-100 text-lg font-normal  leading-normal">
              {t(
                "Platform's dynamic core, empowering innovation and secure digital transactions effortlessly"
              )}
            </div>
          </div>
          {/* 1st div  */}

          {/* 2nd div  */}
          <div className="flex md:justify-end ">
            <div className="flex flex-col gap-5 ">
              {Token_Info.map((e, indx) => {
                return (
                  <div key={indx} className="flex flex-row items-center gap-10">
                    <p
                      className="text-zinc-100
                    text-base
                    font-normal
            
                    leading-normal"
                    >
                      {t(e.label)}
                    </p>{" "}
                    <p className="text-zinc-500 text-base font-normal leading-normal">
                      {t(e.ans)}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
          {/* 2nd div  */}

          <div className="max-w-[822px] col-start-1 col-end-3  text-zinc-500 text-base font-normal font-Inter leading-normal">
            {t(
              "Fuel your digital ventures with Trust AI Token, the key to content creation, staking, NFT minting with AI, and seamless token swaps. Experience collaborative innovation and enhanced security in the crypto realm, as Trust AI Token transcends boundaries, driving the expansion of artificial intelligence across diverse technology sectors. Join us for a future where Trust AI Token unlocks new possibilities in decentralized technologies."
            )}
          </div>
        </div>
        <div className="flex flex-col md:flex-row  justify-start md:justify-center pt-6 gap-2">
          <div className="text-zinc-100 text-base font-bold leading-normal">
            Contract:
          </div>
          <div className="flex flex-row items-center gap-2">
            <a
              className="text-yellow-500 text-base font-normal leading-normal custom-wrap cursor-pointer"
              href="https://bscscan.com/address/0x50C7584be73D20760f1947fFcbF7942822C968C8"
              target="_blank"
              rel="noopener noreferrer"
            >
              {contract}
            </a>
            <CopyToClipboard text={contract} onCopy={handleCopy}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6 text-yellow-500 cursor-pointer"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M8.25 7.5V6.108c0-1.135.845-2.098 1.976-2.192.373-.03.748-.057 1.123-.08M15.75 18H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08M15.75 18.75v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5A3.375 3.375 0 0 0 6.375 7.5H5.25m11.9-3.664A2.251 2.251 0 0 0 15 2.25h-1.5a2.251 2.251 0 0 0-2.15 1.586m5.8 0c.065.21.1.433.1.664v.75h-6V4.5c0-.231.035-.454.1-.664M6.75 7.5H4.875c-.621 0-1.125.504-1.125 1.125v12c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V16.5a9 9 0 0 0-9-9Z"
                />
              </svg>
            </CopyToClipboard>
          </div>
        </div>

        <div className="flex justify-start md:justify-center pt-6">
          <a
            href={BUY_LINK[0].link}
            target="_blank"
            rel="noopener noreferrer"
            className="sButton "
          >
            <div className="bText">{t("Buy Now")}</div>
          </a>
        </div>
      </div>
    </div>
  );
}

export default TokenInfo;
