import React,{useEffect} from "react";
import Tokengenarator from "../../components/Tokencreator/Tokengenarator";
import Staticsection from  "../../components/Tokencreator/Staticsection"
type Props = {};

function Token({}: Props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="">
      <Staticsection/>
      <div className="relative py-20 bg-bggray "> 
        <div className="absolute right-0 z-[0] top-[20%]">
          <img className="w-[100vw] h-[100vh]" src="/layer/Vector12.png" />
        </div>

        <Tokengenarator />
      </div>
    </div>
  );
}

export default Token;
