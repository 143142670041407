import { TOKEN, Field } from "../../typeing";
import BSCToken from "../../config/swap/tokenlist.json";

const tokenListFromSDK = {
  56: BSCToken.tokens,
};

const defaultToken: Record<number, Record<Field, TOKEN>> = {
    56: {
      [Field.INPUT]: {
        name: "Trust Ai",
        address: "0x50C7584be73D20760f1947fFcbF7942822C968C8",
        symbol: "TRT",
        decimals: 18,
        chainId: 56,
        logoURI: "https://app.trust-ai.io/logo.png",
      },
      [Field.OUTPUT]: {
        name: "Binance Pegged USDT",
        address: "0x55d398326f99059fF775485246999027B3197955",
        symbol: "USDT",
        decimals: 18,
        chainId: 56,
        logoURI: "https://tokens.pancakeswap.finance/images/0x55d398326f99059fF775485246999027B3197955.png"

  
      },
    },
  };

  export default defaultToken;
