import React, { useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import {
  getTokeninstance,
  getContractInstanceSigner,
  ConvertEthTonormal,
  getTokeninstanceBysigner,
} from "../../utils/contracthelper";
import { ethers } from "ethers";
import { TRT_TOKEN } from "../../config/nftmint/index";
import ABI from "../../abi/erc721.json";
import userService from "../../service/TrtService";
import { Storenfttoipfs } from "../../data/nft/genarateslice";
import { MINT_CONTRACT, MINT_COST } from "../../config/nftmint/index";
import { RPC_CLIENT } from "../../config/network";
import axios from "axios";

const useUploadnft = (signer: any, account: any, chainId: any) => {
  const [loading, setSellTokenLoading] = useState(false);
  const [ipfsurl, setipfsurl] = useState();
  const [balance, setbalance] = useState("");

  const CheckBalance = async () => {
    try {
      const _provider = new ethers.providers.JsonRpcProvider(RPC_CLIENT[56]);
      setSellTokenLoading(true);
      const myContract = await getTokeninstanceBysigner(TRT_TOKEN, _provider);
      const currentBalance = await myContract.balanceOf(account);

      const userBalance = await ConvertEthTonormal(currentBalance, 18);
      setbalance(userBalance);
      setSellTokenLoading(false);
    } catch (error) {
      console.log(error);
      setSellTokenLoading(false);
    }
  };

  useEffect(() => {
    CheckBalance();
  }, [signer, account]);

  const Storenft = async (
    imageurl: string,
    nftinfo: {
      name: string;
      description: string;
    }
  ) => {
    const toastId = toast.loading("Uploading metadata to IPFS...");
    setSellTokenLoading(true);

    const data = {
      image: imageurl,
      name: nftinfo.name,
      description: nftinfo.description,
    };

    try {
      const res = await userService.storenft(data);
      const url = res.data.ipfsUri;
      setipfsurl(url);
      toast.success("Metadata uploaded successfully. You can mint now.", {
        id: toastId,
      });
      setSellTokenLoading(false);
      return true;
    } catch (error) {
      console.error("Error uploading metadata:", error);
      setSellTokenLoading(false);
      toast.error("Something went wrong. Please try again.", {
        id: toastId,
      });
    }

    return false;
  };
  const checkLimit = async () => {
    try {
      const res = await userService.checkfreelimit();
      const limit = res.data.limit;
      return limit;
    } catch {
      return false;
    }
  };

  // upload local image to pinata
  const UploadLocalImage = async (fileImg: any) => {
    setSellTokenLoading(true);

    try {
      const formData = new FormData();
      formData.append("file", fileImg);
      const resFile = await axios({
        method: "post",
        url: "https://api.pinata.cloud/pinning/pinFileToIPFS",
        data: formData,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_PINATA_JWT}`,
        },
      });
      const ImgHash = `ipfs://${resFile.data.IpfsHash}`;
      setSellTokenLoading(false);
      return ImgHash;
    } catch {
      setSellTokenLoading(false);
      return "";
    }
  };

  // run buy func .
  const HandleRun = async (
    fname: string,
    args: Array<any>,
    startNotification: string,
    whendone: string,
    fees: string
  ) => {
    const _contract = MINT_CONTRACT[chainId];
    if (!_contract) return;
    const name = String(fname);
    setSellTokenLoading(true);
    const toastId = toast.loading(startNotification);
    const myContract = await getContractInstanceSigner(_contract, ABI, signer);
    try {
      const response = await myContract?.[name](...args, {
        value: fees,
      });
      const receipt = await response.wait();

      toast.success(whendone, {
        id: toastId,
      });

      setSellTokenLoading(false);
      return { isDone: true };
    } catch (error: any) {
      console.log(error, "error");

      setSellTokenLoading(false);
      if (error.code == "ACTION_REJECTED") {
        toast.error("User Cancelled The Transaction", {
          id: toastId,
        });
      } else if (error.reason) {
        toast.error(error.reason || "Something went wrong try again", {
          id: toastId,
        });
      } else if (error.data) {
        if (error.data.code == -32000) {
          toast.error(
            "Insufficient funds. Please ensure you have a minting cost.",
            {
              id: toastId,
            }
          );
        } else {
          toast.error("Something went wrong try again", {
            id: toastId,
          });
        }
      } else {
        toast.error("Something went wrong try again", {
          id: toastId,
        });
      }

      return { isDone: false, error: "failed!" };
    }
  };

  return {
    loading,
    HandleRun,
    Storenft,
    ipfsurl,
    checkLimit,
    balance,
    UploadLocalImage,
  };
};

export default useUploadnft;
