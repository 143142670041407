import React from "react";
import { PriceData } from "../Topprice";
type Props = {
  data: PriceData;
};

function Pricebox({ data }: Props) {
  const isPricePositive = data?.priceChange && data?.priceChange >= 0;
  const priceColor = isPricePositive ? "text-green-500" : "text-red-500";

  return (
    <div className="flex flex-row items-center justify-center gap-3 w-full mx-auto">
      <img src={`tokenprice/${data.tokenImg}.png`} className="w-[32px]" />
      <div className="text-white text-base font-normal font-Acme leading-normal">
        {data.name}
      </div>
      <div className="text-white text-base font-normal font-Acme leading-normal flex flex-row gap-3">
        ${data.price.toFixed(4)}{" "}
        <span className={`${isPricePositive ? "text-green-500" : "text-red-500"}`}>
          <span className=""> {isPricePositive ? "↑" : "↓"}</span>
          {Number(Math.abs(data.priceChange)).toFixed(3)}%
        </span>{" "}
      </div>
    </div>
  );
}

export default Pricebox;
