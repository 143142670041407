import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useTranslation } from "react-i18next";
type Props = {
  title: string;
  logo: string;
  dec: string;
  index: number;
};

function Systeambox({ title, logo, dec, index }: Props) {
  const { t } = useTranslation();
  return (
    <div
      className={`flex-col items-center justify-center md:justify-start w-full px-10 ${
        index == 0 ? "" : " lg:border-l-[2px] border-white border-opacity-20"
      }`}
    >
      <div className="pb-5">
        <LazyLoadImage className="mx-auto md:mx-0" src={`/Nft/box/${logo}.png`} />
      </div>
      <div className="flex-col   gap-4 flex flex-1 justify-end">
        <div className=" text-white text-center md:text-start  text-xl md:text-2xl font-bold font-['Syne'] leading-[38px]">
          {t(title)}
        </div>
        <div className=" px-3 md:px-0 opacity-80 text-neutral-100 text-center md:text-start text-base font-normal font-['Inter'] leading-relaxed">
          {t(dec)}
        </div>
      </div>
    </div>
  );
}

export default Systeambox;
