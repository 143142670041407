import React from "react";
import Topprice from "../../components/Header/Topprice";
import Fotter from "../../components/newFotter/Fotter";
import { Header } from "../../components/Header/Header";

export default function Layout(props: any) {
  return (
    <div className="">
       <Topprice />
      <Header /> 
      {props.children}
  
       <Fotter />
    </div>
  );
}
