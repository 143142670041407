import { Fragment, useEffect, useState } from "react";
import {
  CheckIcon,
  ChevronUpDownIcon,
  ChevronUpIcon,
  ChevronDownIcon,
} from "@heroicons/react/20/solid";
import { Listbox, Transition } from "@headlessui/react";
import { MdLanguage } from "react-icons/md";

type Props = {
  handleoptionchange: (value: any) => void;
  data_type: any;
  selected: any;
};
function MyListbox({ handleoptionchange, selected, data_type }: Props) {
  return (
    <Listbox
      value={selected}
      onChange={(e) => {
        handleoptionchange(e);
      }}
    >
      {({ open }) => (
        <div className="relative mt-1 z-0 w-fit items-end  md:px-0">
          <Listbox.Button className=" w-full h-[41px] justify-end rounded-[10px]  flex flex-row items-center  gap-2 border border-yellow-500   z-1   py-2 px-2 md:px-5 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
            {/* <span className="block truncate text-sm">{selected.name}</span> */}
            <MdLanguage className="text-2xl text-white cursor-pointer " />
            <div className="text-white gap-2 text-sm md:text-base font-normal flex flex-row items-center  leading-[18px]  ">
              <p>{selected.name} </p>
              <ChevronDownIcon width={20} className="text-lg text-white" />{" "}
            </div>
          </Listbox.Button>

          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0 "
          >
            <Listbox.Options className="md:absolute relative mt-1 max-h-60 w-full z-[100] rounded-md bg-white border-grayborder  border-[1px] dark:bg-darkbg  py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
              {data_type.map((data_type: any, data_typeIdx: any) => (
                <Listbox.Option
                  key={data_typeIdx}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 text-center text-sm  ${
                      active ? "bg-amber-100 text-amber-900" : "text-gray-900"
                    }`
                  }
                  value={data_type}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={`block  ${
                          selected ? "font-medium" : "font-normal"
                        }`}
                      >
                        {data_type.name}
                      </span>
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  );
}

export default MyListbox;
