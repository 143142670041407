import React from "react";
import Label from "./Label";
type Props = {
  name: string;
  value: any;
  label: string;
  handlevaluechange: (value: any) => void;
  loading:boolean
};

function InputRange({ name, value, label, handlevaluechange ,loading}: Props) {
  const value_n = Number(value);
  const fillPercentage = (value_n / 25) * 100; // Adjust the denominator based on your max range

  const trackGradient = `linear-gradient(90deg, #DAA200 ${fillPercentage}%, #898990 0)`;

  return (
    <div className="flex flex-col gap-3 ">
      <Label name={label} />

      <div className="relative w-full flex items-center">
        <input
          id="input_range"
          type="range"
          min={0}
          max={25}
          className=''
          value={value_n}
          onChange={handlevaluechange}
          name={name}
          style={{ background: trackGradient }}
          disabled={loading}
        />
      </div>

      <div className="text-white text-base md:text-xl  font-bold font-Syne leading-[50px]">
        {value==""?0:value} %
      </div>
    </div>
  );
}

export default InputRange;
