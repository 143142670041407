import React,{useEffect} from "react";
import "./coming.css";
import Navbar from "../Navbar/Navbar";
import { Link } from "react-router-dom";

const Coming = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="main_coming h-[80vh] flex items-center justify-center font-ChakraPetch">
        <div className="text-white p-5">
          <h2 className="text-center text-4xl ">Coming Soon!</h2>
          <p className="text-center py-1 mb-2">
            Stay tuned for updates. Exciting things are on the way!
          </p>
          <Link
            className="transition-all w-fit mx-auto flex items-center gap-1 px-4  hover:opacity-70 font-semibold  sm:h-[44px]  h-auto sm:py-0 py-2 bg-gradient-to-r bg-white text-black  rounded-[5px]  sm:flex-initial flex-1 justify-center   disabled:bg-slate-500 disabled:cursor-not-allowed"
            to="/"
          >
            {" "}
            Back to home
          </Link>
        </div>
      </div>
    </>
  );
};

export default Coming;
