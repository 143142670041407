import React from "react";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
type Props = {};

function Welcome({}: Props) {
  const { t } = useTranslation();
  return (
    <div className="py-20">
      {/* text */}
      <div className="space-y-3">
        <div className=" text-center text-yellow-400 text-sm font-semibold font-['Inter'] leading-snug">
          {t("WELCOME")}
        </div>
        <div className="w-[90vw] md:max-w-[680px] leading-tight	 mx-auto text-center text-white text-3xl md:text-5xl font-bold font-['Syne']">
          {t("Easy ways to use AI tools, and tools to Mint AI.")}
        </div>
        <div className="w-[90vw] py-4 md:py-10 md:max-w-[1000px] mx-auto opacity-80 text-center text-neutral-50 text-base md:text-lg font-normal font-['Inter'] leading-relaxed">
          {t(
            "Innovative NFT platform leveraging AI for guaranteed uniqueness. Our AI ensures each NFT possesses distinct traits, empowering users/artists to enhance existing NFTs creatively. We aim to establish a marketplace where minting, selling, and buying NFTs is seamless. Anticipate the launch of our fully functional marketplace, ushering in a revolution in digital art creation and commerce."
          )}
        </div>
        <LazyLoadImage
          className="mx-auto w-full xl:max-w-[965px] px-5"
          src="/Nft/welcomesec.webp"
        />
      </div>
      {/* text */}

      {/* image */}
      <div></div>
      {/* image */}
    </div>
  );
}

export default Welcome;
