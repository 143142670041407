import React from "react";
import { Field, TOKEN } from "../../../typeing";
import { toggleTokenListModal } from "../../../store/swapslice";
import { useAppSelector, useAppdispatch } from "../../../hooks/redux";
import { Token, Currency } from "@pancakeswap/sdk";
import ClipLoader from "react-spinners/ClipLoader";
import { formatAmount } from "../../../utils/numbers";
import Skeleton from "../../Staking/pool/Skeleton";
import Balanceadder from ".//Balanceadder";
type Props = {
  field: Field;
  currencies: TOKEN | undefined;
  amount: string;
  handleChange: (Field: Field, event: any) => void;
  loading: boolean;
  bestRouteAmount?: string | null; // Additional prop for bestRoute output amount
  currencyBalances: { [field in Field]?: string };
  balanceload: boolean;
};

function SwapCurrencyInputPanel({
  field,
  currencies,
  amount,
  handleChange,
  loading,
  bestRouteAmount,
  currencyBalances,
  balanceload,
}: Props) {
  const dispatch = useAppdispatch();
  const showModel = () => {
    dispatch(toggleTokenListModal({ isOpen: true, modelType: field }));
  };

  const handlePercentageClick = (percentage: string) => {
    const inputBalance = currencyBalances[field];
    if (inputBalance == undefined) return;
    const _inputbalance = Number(inputBalance);

    if (percentage === "Max") {
      handleChange(field, _inputbalance.toFixed(4));
    } else {
      const percentageValue = parseFloat(percentage) / 100;
      const calculatedAmount = (percentageValue * _inputbalance).toFixed(4);
      handleChange(field, calculatedAmount);
    }
  };
console.log(currencies?.logoURI);

  return (
    <div className="bg-green-200  bg-opacity-10  rounded-3xl  px-5  gap-2.5 py-3 ">
      <div className=" flex flex-grow-1 w-full justify-between items-center h-fit   ">
        {" "}
        <div>
          <button
            onClick={showModel}
            className="flex flex-row items-center gap-x-2 hover:opacity-80"
          >
            <img className="w-[35px] rounded-full" src={currencies?.logoURI} />
            <span className="text-right text-white text-sm md:text-base font-bold font-Inter leading-normal">
              {currencies?.symbol || ""}
            </span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4 hidden md:block text-white"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m19.5 8.25-7.5 7.5-7.5-7.5"
              />
            </svg>
          </button>
        </div>
        <div>
          <div className="text-white text-sm opacity-70 text-right">
            {balanceload ? (
              <div className="flex justify-end">
                {" "}
                <Skeleton />
              </div> // Show Skeleton when balanceload is true
            ) : (
              `Balance: ${formatAmount(Number(currencyBalances[field]) || 0)}`
            )}
          </div>
          <input
            // disabled={loading}
            type="text"
            title="Only numeric values are allowed."
            pattern="^[0-9]*(\.[0-9]*)?$"
            placeholder="0.00"
            className={`${loading ? "opacity-40" : ""} p-0  font-bold
              outline-none border-transparent text-base md:text-xl
              focus:border-transparent focus:ring-0 
              text-end bg-transparent border-none forced-colors:bg-clip-border focus:bg-none focus:border-none text-white  rounded-2xl w-full h-[48px]`}
            value={amount}
            onChange={(e) => handleChange(field, e.target.value)}
            style={{
              border: "none",

              appearance: "none",
            }}
          />
          <Balanceadder handlePercentageClick={handlePercentageClick} />
        </div>
      </div>
    </div>
  );
}

export default SwapCurrencyInputPanel;
