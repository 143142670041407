import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useTranslation } from "react-i18next";
type Props = {
  logo: string;
  index: number;
};

function Topnftbox({logo, index }: Props) {
  const { t } = useTranslation();
  return (
    <div
      style={{}}
      className={`flex-col w-fit mx-auto h-fit px-10 relative  py-3  rounded-3xl items-center  ${
        index == 1 ? "md:mt-[100px]" : " z-[10]"
      }`}
    >
      <LazyLoadImage src={`/Nft/topnft/${logo}.png`} />
    </div>
  );
}

export default Topnftbox;
