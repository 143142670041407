import { ToastContainer, toast } from "react-toastify";
import { TokenInfo } from "../components/Tokencreator/Tokengenarator";
import { ethers } from "ethers";

// parms checker
export const encodeConstructorArguments_basic = (
  tokenInfo: any,
  user: string,
  Admin: string
) => {
  try {
    const decimals = parseInt(tokenInfo.decimals, 10);
    const initialSupply = ethers.utils.parseUnits(
      tokenInfo.totalSupply,
      decimals
    ); // Assuming 1,000,000 tokens

    const supply = initialSupply.toString();

    const abiCoder = new ethers.utils.AbiCoder();
    const encodedParameters = abiCoder.encode(
      ["string", "string", "uint8", "uint256", "address", "address"],
      [
        tokenInfo.name,
        tokenInfo.symbol,
        tokenInfo.decimals,
        supply,
        user,
        Admin,
      ]
    );
    console.log(encodedParameters.slice(2), "encodedParameters");
    return encodedParameters.slice(2);
  } catch {}
};

export const EncodeData = () => {
  const abiCoder = new ethers.utils.AbiCoder();
  const typesArray = [
    { type: "string", name: "messanger" },
    { type: "string", name: "username" },
    { type: "string", name: "nome" },
    { type: "string", name: "cognome" },
    { type: "string", name: "email" },
  ];

  const data =
    "0x000000000000000000000000289c38078c66605868674bbc9d945c35801d74bc000000000000000000000000000000000000000000000000000000000000004000000000000000000000000000000000000000000000000000000000000000035341730000000000000000000000000000000000000000000000000000000000";
  const decodedParameters = abiCoder.decode(["address", "string"], data);
  console.log(decodedParameters);

  console.log(JSON.stringify(decodedParameters, null, 4));
};

export const encodeConstructorArguments_tax = (
  tokenInfo: any,
  swapRouter: string,
  user: string,
  Admin: string
) => {
  try {
    const decimals = parseInt(tokenInfo.decimals, 10);
    const initialSupply = ethers.utils.parseUnits(
      tokenInfo.totalSupply,
      decimals
    );
    const supply = initialSupply.toString();

    console.log("1");

    const feeamountsBuy = tokenInfo.buyTax
      ? Object.values(tokenInfo.buyTax).map((fee: any) =>
          ethers.BigNumber.from(fee).mul(100).toString()
        )
      : [];

    const feeamountsSell = tokenInfo.sellTax
      ? Object.values(tokenInfo.sellTax).map((fee: any) =>
          ethers.BigNumber.from(fee).mul(100).toString()
        )
      : [];

    console.log("2", feeamountsSell);

    const abiCoder = new ethers.utils.AbiCoder();
    const encodedParameters = abiCoder.encode(
      [
        "string",
        "string",
        "uint8",
        "address",
        "uint256",
        "uint256[3]",
        "uint256[3]",
        "address",
        "address",
        "address",
      ],
      [
        tokenInfo.name,
        tokenInfo.symbol,
        tokenInfo.decimals,
        swapRouter,
        supply,
        feeamountsBuy,
        feeamountsSell,
        tokenInfo.marketingwallet,
        user,
        Admin,
      ]
    );

    console.log(encodedParameters.slice(2), "encodedParameters");
    return encodedParameters.slice(2);
  } catch (error) {
    console.error("Error encoding constructor arguments:", error);
    // Handle the error or return a default value
    return "";
  }
};

export const constructorArguementsMaker = async (
  tokenType: number,
  tokenInfo: any,
  user: string,
  Admin: string,
  router: string
) => {
  if (tokenType == 0) {
    const arg: any = encodeConstructorArguments_basic(tokenInfo, user, Admin);

    return arg;
  } else {
    const arg: any = encodeConstructorArguments_tax(
      tokenInfo,
      router,
      user,
      Admin
    );

    return arg;
  }
};

const areFieldsEmpty = (taxFields: any) => {
  return Object.entries(taxFields).some(([key, value]) => {
    return (
      typeof value === "string" &&
      (value.trim() === "" || value.trim() === undefined)
    );
  });
};

export const TokenInputcheckforTax = (
  tokenInfo: TokenInfo,
  Tokenindex: number
) => {
  if (Tokenindex != 0) {
    let generalInfoErrorMessage = "";

    // Check for empty fields in general info
    const generalInfoEmptyFields = Object.entries(tokenInfo).filter(
      ([key, value]) => typeof value === "string" && value.trim() === ""
    );

    if (generalInfoEmptyFields.length > 0) {
      const generalInfoEmptyFieldNames = generalInfoEmptyFields
        .map(([key]) => key)
        .join(", ");

      generalInfoErrorMessage = `Please fill in the following fields: ${generalInfoEmptyFieldNames}\n`;
    }

    // Display alert for general info if there are any errors
    if (generalInfoErrorMessage.trim() !== "") {
      toast.warn(generalInfoErrorMessage);
    }

    // Check for empty fields in buyTax
    const emptyBuyTaxFields = Object.entries(tokenInfo.buyTax).filter(
      ([key, value]) => {
        return (
          typeof value === "string" &&
          (value.trim() === "" || value.trim() === undefined)
        );
      }
    );
    // Check for empty fields in sellTax
    const emptySellTaxFields = Object.entries(tokenInfo.sellTax).filter(
      ([key, value]) => {
        return (
          typeof value === "string" &&
          (value.trim() === "" || value.trim() === undefined)
        );
      }
    );

    // Display alerts for empty fields
    let errorMessage = "";

    if (emptyBuyTaxFields.length > 0) {
      const emptyBuyTaxFieldNames = emptyBuyTaxFields
        .map(([key]) => key)
        .join(", ");

      errorMessage += `Please fill in the following fields in Buy: ${emptyBuyTaxFieldNames}\n`;
    }

    if (emptySellTaxFields.length > 0) {
      const emptySellTaxFieldNames = emptySellTaxFields
        .map(([key]) => key)
        .join(", ");

      errorMessage += `Please fill in the following fields in Sell: ${emptySellTaxFieldNames}\n`;
    }

    if (errorMessage.trim() !== "") {
      toast.warn(errorMessage);
    }

    if (emptySellTaxFields.length == 0 && emptyBuyTaxFields.length == 0) {
      return true;
    } else {
      return false;
    }
  } else {
    let generalInfoErrorMessage = "";

    // Check for empty fields in general info
    const generalInfoEmptyFields = Object.entries(tokenInfo).filter(
      ([key, value]) => typeof value === "string" && value.trim() === ""
    );

    if (generalInfoEmptyFields.length > 0) {
      const generalInfoEmptyFieldNames = generalInfoEmptyFields
        .map(([key]) => key)
        .join(", ");

      generalInfoErrorMessage = `Please fill in the following fields: ${generalInfoEmptyFieldNames}\n`;
    }

    // Display alert for general info if there are any errors
    if (generalInfoErrorMessage.trim() !== "") {
      toast.warn(generalInfoErrorMessage);
    }
    if (generalInfoEmptyFields.length == 0) {
      return true;
    } else {
      return false;
    }
  }
};
