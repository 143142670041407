import React from "react";
import { Topnftcontent } from "../../../config/nftmint/index";
import Topnftbox from "./Helper/Topnftbox";
type Props = {};



function Topnft({}: Props) {
  return (
    <div className="w-full md:max-w-7xl mx-auto py-24">
         <div className="  text-center text-white text-2xl md:text-5xl font-bold font-['Syne']">
         TOP NFT
      </div>
      <div className=" mx-auto flex flex-wrap lg:grid lg:grid-cols-3  pt-10 md:pt-24">
        {Topnftcontent.map((e, indx) => {
          return (
            <Topnftbox
              key={indx}
              index={indx}
              logo={e.logo}

            />
          );
        })}
      </div>
    </div>
  );
}

export default Topnft;
