import React, { useState, useEffect } from "react";
import axios from "axios";
import Linechart from "../Chart/Linechart";
import { useTranslation } from "react-i18next";
import service from "../../service/TrtService";
type Props = {};

interface PriceData {
  // Adjust the properties based on the actual structure of your data
  price: number;
  timestamp: number;
}

function Pricechart({}: Props) {
  const { t } = useTranslation();
  const [priceData, setPriceData] = useState<PriceData[] | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    // Fetch data from the API using Axios
    service
      .getPrice()
      .then((response) => {
        // Update the state with the fetched data
        setPriceData(response.data.data);
        setLoading(false); // Set loading to false once data is received
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false); // Set loading to false in case of an error
      });
  }, []); // The empty dependency array ensures that the effect runs only once on component mount

  return (
    <div
      className="py-5 md:py-10 stopscrollmargin"
      id="chart"
      data-aos="fade-right"
      data-aos-duration="3000"
    >
      <div className=" text-center text-yellow-500 text-base font-normal font-Acme leading-snug">
        Chart
      </div>
      <div className="mt-3 text-center text-zinc-100 mb-2 text-2xl md:text-4xl font-normal font-Syne ">
        {t("Price Trust AI")}
      </div>

      {loading ? (
        <p className="text-center py-5 text-white">Loading chart...</p>
      ) : priceData ? (
        <div className="max-w-7xl mx-auto">
          {/* Display the price data */}
          <Linechart
            data={priceData}
            timestampKey="timestamp"
            valueKey="price"
            title="TRT/USDT"
            hoverlabel="price"
          />
        </div>
      ) : (
        <p>No data available</p>
      )}
    </div>
  );
}

export default Pricechart;
