import { ethers } from "ethers";
import { PoolType } from "../config/Pool";
import {
  FormatUnit,
  ConvertEthTonormal,
  getContractInstance,
  Erc20Balance
  
} from "../utils/contracthelper";
interface PoolData {
  totaldeposit: number;
  staked: number;
}



export const Fixed_Stake = async (
  instance: any,
  user: string | undefined,
  stakingTokenDecimals: number
): Promise<PoolData> => {
  let totaldeposit = 0;
  const totalstaked = Number((await instance.totalStaked()).toString()) || 0;
  console.log(totalstaked, "totalstaked");

  if (totalstaked != undefined && totalstaked != 0) {
    totaldeposit = totalstaked / 10 ** stakingTokenDecimals;
  }
  let staked = 0;
  // let stakeTokenbalance = await Erc20Balance()

  // if user have get his total staked value

  if (user) {
    console.log("am loging.....");

    const userStake = await instance.totalStakedbyuser(user);
    staked = Number(userStake) / 10 ** stakingTokenDecimals;
  }

  return {
    totaldeposit,
    staked,
  };
};

export const getPoolData = async (
  poolType: PoolType,
  instance: any,
  user: string | undefined,
  stakingTokenDecimals: number
): Promise<PoolData | undefined> => {
  try {
    let result: PoolData | undefined;
    switch (poolType) {
      case PoolType.fixedLock:
        result = await Fixed_Stake(instance, user, stakingTokenDecimals);
        break;

      default:
        // Handle unsupported pool types or return a default value
        result = {
          totaldeposit: 0,
          staked: 0,
        };
        break;
    }
    return result;
  } catch (error) {
    return {
      totaldeposit: 0,
      staked: 0,
    };
  }
};
