import React,{useEffect} from "react";
import Hero from "../../components/Home/Hero";
import Navbar from "../../components/Navbar/Navbar";
import About from "../../components/Home/About";
import Systeam from "../../components/Home/Systeam";
import Tokenomics from "../../components/Home/Tokenomics";
import TokenInfo from "../../components/Home/TokenInfo";
import Tracking from "../../components/Home/Tracking";
import Features from "../../components/Home/Features"
import Nft from "../../components/Home/Nft";
import Whitepaper from "../../components/Home/Whitepaper";
import Roadmap from "../../components/Home/Roadmap";
import FAQ from "../../components/Home/FAQ";
import Fotter from "../../components/newFotter/Fotter";
import { Header } from "../../components/Header/Header";
import Slide_Image from "../../components/Home/Slide"
import Pricechart from "../../components/Home/Pricechart"

//header
import Topprice from "../../components/Header/Topprice"

//top
import GoToTop from "../../components/Gtop/GoToTop"

type Props = {};

function Home({}: Props) {
  useEffect(() => {
    window.scrollTo(0, 0);

    return () => {
    };
  }, []);





  return (
    <div className="bg-[#111111] relative ">
      {/* <Topprice/> */}
      <GoToTop/>
       {/* <Header/> */}
     <div className='overflow-x-hidden  mt-[-200px]  '>
    
      <Hero />
      <About/>
      <Systeam/>
      <Nft/>
      <Tokenomics/>
     
      <TokenInfo/>
      <Pricechart/>
      <Tracking/>
      <Slide_Image/>
      <Features/>
    
      <Whitepaper/>
       <Roadmap/>
      <FAQ/>
      {/* <Fotter/> */}
     </div>
    </div>
  );
}

export default Home;
