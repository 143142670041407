import { Currency, Token, Percent, CurrencyAmount ,Native} from "@pancakeswap/sdk";
export type TOKEN = {
  name: string;
  address: string;
  symbol: string;
  decimals: number;
  chainId: number;
  logoURI: string;
  isNative?:boolean
};
export enum Field {
  INPUT = "INPUT",
  OUTPUT = "OUTPUT",
}
export type SwapInfo = {
  currencies: { [field in Field]?: TOKEN };
  currencyBalances: { [field in Field]?: string};
  // inputTax: Percent;
  // outputTax: Percent;
  parsedAmount?: CurrencyAmount<Currency>;
  trade: any;
  allowedSlippage: string;
  txTime:string,
  activeField:Field
  loading: "idle" | "pending" | "done" | "error";
};

export interface RouteTradeParams {
  chainId: number;
  currencyAmount: number;
  inputCurrency: TOKEN | undefined;
  outputCurrency: TOKEN | undefined;
  TradeType: any;
}