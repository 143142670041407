import React, { useEffect, useState } from "react";
import LineProgress from "./LineProgress";
import { pools } from "../../../config/Pool";
import { ConvertEthTonormal } from "../../../utils/contracthelper";
import { formatDate, calculateDaysInfo } from "../../../utils/date";
import { useAppdispatch } from "../../../hooks/redux";
import {
  formatNumber,
  calculateReward,
  formatAmount,
} from "../../../utils/numbers";
import { ToastContainer, toast } from "react-toastify";
import ScaleLoader from "react-spinners/ScaleLoader";
import { useAccount } from "wagmi";
import { StakedData } from "../../../data/stakedata/fetchStakedData";
import useEnd from "../../../hooks/useEnd";
import { getStakedata } from "../../../data/stakedata/Getstakedata";
import { getALLpool } from "../../../data/pool/getPool";
import {useEthersSigner} from "../../../hooks/useEthersSigner"

type Props = {
  data: StakedData;
  indx: number;
  isbutton?: boolean;
  endLoad?: boolean;
  endStakeFunc?: any;
  poolIndex: number;
};


function Stakinfo({
  data,
  indx,
  isbutton,
  endLoad,
  endStakeFunc,
  poolIndex,
}: Props) {
  const dispatch = useAppdispatch();
  const { address ,chain} = useAccount();

  const { startTime, endTime, amount, poolId, stakeId } = data || {};
  const [apy, setAPY] = useState(0);
  const  signer  = useEthersSigner({chainId:chain?.id});

  const { percentage, daysLeft, daysPassed } = calculateDaysInfo(
    Number(startTime),
    Number(endTime)
  );
  const {
    stakingTokenDecimals,
    subpool,
    ABI,
    contract,
    staketoken,
    rewardTokenDecimals,
  } = pools[poolIndex];
  const { loading, HandleRun } = useEnd(
    signer,
    address,
    contract,
    staketoken,
    ABI,
    stakingTokenDecimals
  );

  const Amount = Number(amount) / 10 ** stakingTokenDecimals;
  useEffect(() => {
    const subpools = subpool?.find((pool) => pool.index == poolId);
    setAPY(subpools?.apy || 0);
  }, [poolId]);

  const reward =
    calculateReward(
      Number(startTime),
      Number(endTime),
      apy,
      amount,
      rewardTokenDecimals,
      stakingTokenDecimals
    ) || 0;

  
  

  


  const totalAmount = reward / 10 ** rewardTokenDecimals + Amount;

  const HandleEnd = async () => {
    if (daysLeft == "Unlocked") {
      HandleRun("EndStake", [poolId, stakeId], "unstake", 1).then((E) => {
        if (E.isDone) {
          dispatch(getStakedata({ user: address }));
          dispatch(getALLpool({user:address}))
        }
      });
    } else {
      toast.warn(`You have to wait ${daysLeft} to end your stake!`);
    }
  };

  const EndStake = async () => {
    
      HandleRun("withdraw", [poolId, stakeId], "unstake", 1).then((E) => {
        if (E.isDone) {
          dispatch(getStakedata({ user: address }));
          dispatch(getALLpool({user:address}))
        }
      });
    }
  

  const Stat = (p: any) => (
    <div className="flex flex-col items-center gap-1.5 w-32 max-[750px]:w-20 max-[600px]:mx-auto ">
      <span className="text-[10px] text-[#fff5]">{p.label}</span>
      <div className="text-[15px] flex gap-0.5 items-center text-white">
        {p.children}
      </div>
    </div>
  );

  return (
    <div
      className={`	border-[1px] border-[#ffffff17] rounded-md py-4 pr-4
      flex justify-between items-center relative overflow-hidden
      gap-y-5 max-[600px]:grid max-[600px]:grid-cols-3 max-[600px]:px-4 `}
    >
      <div className="absolute inset-0 bg-black -z-20" />
      <div
        className="absolute inset-0 bg-gradient-to-r from-[#fff0] to-[#ffffff17] z-0 "
        style={{ width: 100 + "%" }}
      />
      <Stat label="Apy">{`${apy}%`}</Stat>

      <div className="flex items-center border-x-[0px] border-[#fff1] max-[600px]:col-span-3 max-[600px]:row-start-1 max-[600px]:justify-between">
        <Stat hex label="Staked">
          {formatAmount(Amount)} TRT
        </Stat>

        <Stat hex label="Yield">
          {formatAmount(reward / 10 ** rewardTokenDecimals)} TRT
        </Stat>

        <Stat
          hex
          label={
            <>
              <span className="usdSign">Total</span>
            </>
          }
        >
          {formatAmount(totalAmount)} TRT
        </Stat>
      </div>

      <Stat label="Unlock">{daysLeft}</Stat>
      <div className="flex items-center flex-col gap-5 justify-center relative z-10">
        <button
          onClick={() => HandleEnd()}
          className="text-black hover:opacity-70 transition-all px-3 py-1 rounded-xl flex items-center uppercase bg-zinc-50"
        >
          end {loading}
        </button>

      

      </div>
    </div>
  );
}

export default Stakinfo;
