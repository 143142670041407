import React from 'react'

type Props = {
  toggleToken:()=>void
}

function MiddeToggle({toggleToken}: Props) {
  return (
    <div className="w-[40px] mx-auto py-5 relative hover:scale-105 transition-all">
   <button onClick={()=>toggleToken()}>
   <img src="/SWAP.png"/>
   </button>
    </div>
  )
}

export default MiddeToggle