import React from "react";
import { useTranslation } from "react-i18next";
type Props = {};

function Whitepaper({}: Props) {
  const { t } = useTranslation();
  const Box_style = "w-[95%] md:w-[303px] py-10   bg-[#141414] rounded-[20px] ";
  return (
    <div className="stopscrollmargin mx-auto py-[100px] bg-gradient-to-b from-neutral-900 via-yellow-500 to-neutral-900 px-3 ">
      <div  data-aos="fade-up" data-aos-easing="ease-in-out" data-aos-duration="2000"  className="text-white text-center text-4xl font-bold font-Syne uppercase leading-[42.64px]">
        {t("Read Our Documents")}
      </div>
      <div  data-aos="fade-up" data-aos-easing="ease-in-out" data-aos-duration="2000"  className="max-w-[413px] pt-2 mx-auto text-center font-Inter  text-white text-base font-normal  leading-normal">
        {t(
          "Here is our full documents that help you to understand deeply about us and our operation"
        )}
      </div>

      <div className="flex flex-wrap justify-around pt-10 gap-3 max-w-7xl mx-auto ">
        <div
          className={Box_style}
          data-aos="fade-up" data-aos-easing="ease-in-out" data-aos-duration="2000" 
          style={{
            border: "1px solid #DAA200",
            borderBottom: "none",
            borderLeft: "50%",
            borderRight: "50%",
          }}
        >
       <img src="/document/audit.png" className="p-5 max-w-[200px]  mx-auto" />
          <div className="text-white px-5  text-center text-base font-normal  uppercase font-Acme leading-[18px]">
            {t("whitepaper")}
          </div>
          <a rel="noopener noreferrer"  target="_blank"  href="https://trust-ai-1.gitbook.io/whitepaper-trust-ai/" className="sButton mt-5 py-3 px-6 mx-auto flex w-fit">
            <div className="bText">{t("Read more")}</div>
          </a>
        </div>
        <div
          className={Box_style}
          data-aos="fade-up" data-aos-easing="ease-in-out" data-aos-duration="2000" 
          style={{
            border: "1px solid #DAA200",
            borderBottom: "none",
            borderLeft: "50%",
            borderRight: "50%",
          }}
        >
                <img src="/document/audit1.png" className="p-5 max-w-[200px]  mx-auto" />
          <div className="text-white text-center px-5 text-base font-normal   font-Acme uppercase leading-[18px]">
            {t("Certik Audit & KYC Onboarding")}
          </div>
          <a rel="noopener noreferrer" href="https://skynet.certik.com/projects/trust-ai" target="_blank" className="sButton mt-5 py-3 px-6 mx-auto flex w-fit">
          <div className="bText">{t("Read more")}</div>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Whitepaper;
