import { createAsyncThunk } from "@reduxjs/toolkit";
import { RouteTrade } from "../../utils/swap/bsc/Route";
import { TOKEN } from "../../typeing";
import { TradeType } from "@pancakeswap/sdk";
import axios from "axios";
export const getRoute = createAsyncThunk(
  "getRoute",
  async (
    parms: {
      chainId: number;
      currencyAmount: number;
      inputCurrency: TOKEN | undefined;
      outputCurrency: TOKEN | undefined;
      TradeType: TradeType;
    },
    { dispatch, rejectWithValue, signal }
  ) => {
    try {
 
  
     
      if (signal.aborted) {
        throw new Error('stop the work, this has been aborted!')
      }
      // Attach the signal to the input parameters
      const paramsWithAbortSignal = {
        ...parms,

      };
      const trade = await RouteTrade(
        parms.chainId,
        parms.currencyAmount,
        parms.inputCurrency,
        parms.outputCurrency,
        parms.TradeType,
        []
      );

      return trade;
    } catch (e) {

        return rejectWithValue("Request aborted");
    }
  }
);
