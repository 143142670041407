import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAppSelector, useAppdispatch } from "../../hooks/redux"; // Assuming you have a custom Redux hook
import { Field, TOKEN, SwapInfo } from "../../typeing";
import { useAccount, useSignMessage } from "wagmi";
import Userservice from "../../service/TrtService";
import { getuserlogin, getuserMe } from "../../data/nft/loginreducer";
import { createImage } from "../../data/nft/genarateslice";
import { addLocalImage } from "../../store/Nftslice";
const useLogin = () => {
  const dispatch = useAppdispatch(); // Replace with your custom Redux hook
  const { signMessageAsync } = useSignMessage();
  const { address } = useAccount();

  const { signin, login, loading, genarateload, imageurl, ipfsuri } =
    useAppSelector((state) => state.Nftslice); // Replace with your Redux state path

  // login user

  const Loginuser = async () => {
    const data = {
      wallet: address,
    };
    dispatch(getuserlogin({ info: data }));
  };

  useEffect(() => {
    if (address) {
      dispatch(getuserMe({ wallet: address }));
    }
  }, [address, login]);

  const genarateImage = (data: any) => {
    dispatch(createImage({ data }));
  };

  const addImage = (url: string) => {
    dispatch(addLocalImage({ url: url }));
  };

  return {
    Loginuser,
    signin,
    login,
    loading,
    genarateload,
    imageurl,
    genarateImage,
    ipfsuri,
    addImage,
  };
};

export default useLogin;
