import React, { useEffect, useState } from "react";
import axios from "axios";
import { pair } from "../../config/Tokenprice";
import Pricebox from "./Helper/Pricebox";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import { useMediaQuery } from "react-responsive";
import Marquee from "react-fast-marquee";

type Props = {};
interface Pair {
  name: string;
  pair: string;
  tokenImg: string;
}

export interface PriceData {
  name: string;
  price: number;
  priceChange: number;
  tokenImg: string;
}

export const getPairPrices = async (pairs: Pair[]) => {
  try {
    const prices: PriceData[] = [];
    const baseURL = "https://api.dexscreener.com/latest/dex/pairs/bsc/";
    const pairsString = pairs.map((pair) => pair.pair).join(",");

    const response = await axios.get(`${baseURL}${pairsString}`);
    const data = await response.data?.pairs;

    if (data) {
      for (const pair of pairs) {
        const matchedPair = data.find(
          (response: any) =>
            response.pairAddress.toLowerCase() == pair.pair.toLowerCase()
        );

        if (matchedPair) {
          const priceData: PriceData = {
            name: pair.name,
            price: parseFloat(matchedPair.priceUsd),
            priceChange: matchedPair.priceChange.h24,
            tokenImg: pair.tokenImg,
          };
          prices.push(priceData);
        }
      }

      return prices;
    }
  } catch (error) {
    console.error("Error fetching pair prices:", error);
    return [];
  }
};

function Topprice({}: Props) {
  const [prices, setPrices] = useState<PriceData[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getPairPrices(pair);
        if (data) {
          setPrices(data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

 
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)'
  });


  return (
    <div className="bg-[#212121] z-40 min-h-[75px] relative flex flex-row items-center justify-center ">
      {prices ? (
        <div className="w-full relative ">
          <Marquee
            direction="right"
            autoFill={true}
            pauseOnHover={isDesktopOrLaptop?true:false}
            play={true}
            pauseOnClick={true}
            speed={15}
          >
            {prices.map((e, indx) => {
              return (
                <div
                  key={indx}
                  className="w-fit flex items-center space-x-3 mx-auto gap-4 px-4"
                >
                  <Pricebox data={e} key={indx} />{" "}
                </div>
              );
            })}
          </Marquee>
        </div>
      ) : null}
    </div>
  );
}

export default Topprice;
