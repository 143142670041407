import React from "react";
import CardComponent from "./Helper/card/CardComponent";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
type Props = {};

function Systeam({}: Props) {
  const { t } = useTranslation();

  return (
    <div className="stopscrollmargin relative py-5 md:py-[50px] " id="service" >
      <img src="/Image/Vector12.png" className="absolute bottom-[-38%] right-[-34%] w-[100vw] h-[100vh]"/>
      <div className=" flex flex-col  relative  xl:grid xl:systeamGrids   gap-10 max-w-7xl mx-auto px-3 ">
      
        <div data-aos="fade-up" data-aos-duration="1000" className="md:row-span-2 w-full bg-[#232325] flex flex-col items-start xl:max-w-[524px] rounded-xl  ">
        
          <div className="px-10 pt-10 ">
            <img
              className="md:max-w-[439px] md:max-h-[439px]"
              src="/gif/stake.gif"
            />
          </div>
          <div className="p-10 flex justify-center md:justify-start flex-col gap-7">
            <h1 className="text-center md:text-start    text-zinc-100 text-3xl md:text-4xl font-bold uppercase leading-[42.64px]">
              {t("Staking")}
            </h1>
            <div className="max-w-[394px] text-center md:text-start  text-zinc-500 text-sm font-normal  uppercase leading-normal tracking-wider">
             {t("Experience the power of AI-driven technology, providing you with unmatched trust and transparency. Join Trust AI today and unlock a new era of seamless staking that empowers you to grow your wealth effortlessly.")}
            </div>
            <button className="sButton mx-auto md:mx-0 w-fit ">
              <Link to="/stake" className="bText">{t("Stake Now")}</Link>
            </button>
          </div>
        </div>
        <CardComponent
          imageSrc="/gif/Swap.gif"
          title={t("Swap")}
          description={t("Our innovative swap process allows you to effortlessly exchange digital assets with just a few clicks. Experience lightning-fast transactions, low fees, and unparalleled security on our cutting-edge platform.")}
          buttonText={t("Swap")}
          link="/swap"
        />
        <CardComponent
          imageSrc="/gif/Contract.gif"
          title={t("Create Contract")}
          description={t("Build and deploy smart contracts effortlessly with Trust AI. Empower your business with our intuitive platform and unlock the potential of decentralized applications.")}
          buttonText={t("Create Now")}
          link="/createTokenmaker"
        />
      </div>
    </div>
  );
}

export default Systeam;
