import React, { useEffect } from "react";
import { useAppSelector, useAppdispatch } from "../../hooks/redux";
import { getStakedata } from "../../data/stakedata/Getstakedata";
import ActiveStake from "../../components/Staking/Activestake/ActiveStake";

import Topprice from "../../components/Header/Topprice";
import Fotter from "../../components/newFotter/Fotter";
import { Header } from "../../components/Header/Header";
import { useAccount } from "wagmi";
import { useParams } from "react-router-dom";
import { getALLpool } from "../../data/pool/getPool";
type Props = {};

function Stakeoverview({}: Props) {
  const { address } = useAccount();
  const { poolID } = useParams();

  const dispatch = useAppdispatch();
  const { pools, loading, stakedata } =
    useAppSelector((state) => state.pool) || {};

  useEffect(() => {
    if (address) {
      dispatch(getStakedata({ user: address }));
    }
  }, [address]);

  useEffect(() => {
    if (address) {
      if (pools.length == 0) {
        dispatch(
          getALLpool({
            user: address,
          })
        );
      }
    }
  }, [address]);

  return (
    <div className="font-ChakraPetch   bg-opacity-15 bg-cover bg-center  bg-fixed  ">
      <div className="border-t border-x border-[#111] z-10 ">
        <ActiveStake
          data={stakedata?.activestake}
          load={stakedata.loading == "done"}
          poolID={poolID ? Number(poolID) : 0}
        />
      </div>
    </div>
  );
}

export default Stakeoverview;
