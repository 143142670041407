import React from "react";
import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/20/solid";
import { useTranslation } from "react-i18next";
const faq = [
  {
    qu: "What is Trust AI?",
    ans: [
      "Trust AI is a revolutionary blockchain project integrating artificial intelligence into smart contracts. It aims to redefine trust and security in the crypto space.",
    ],
  },
  {
    qu: "What is Trust AI Token?",
    ans: [
      "Trust AI Token (TRT) is the native utility token of the Trust AI platform, empowering users to engage in various activities, including staking and NFT transactions.",
    ],
  },
  {
    qu: "How to Contact the Trust AI Team?",
    ans: [
      "For direct communication with the Trust AI team, you can reach out through our official channels on Discord, Telegram, or via email at (contact@trust-ai.io).",
    ],
  },
  {
    qu: "What is the Bonus for Staking?",
    ans: [
      "Stakers enjoy competitive bonuses based on the duration of their staking commitment. The longer you stake, the higher the bonus, creating an incentivized environment for long-term participation.",
    ],
  },
  {
    qu: "Will Stakers Benefit from Generated Revenues?",
    ans: [
      "Absolutely! Stakers are entitled to a share of the revenues generated by the Trust AI platform. This distribution mechanism ensures that our community actively participates in the success of the project.",
    ],
  },
  {
    qu: "Where to Buy Trust AI Token?",
    ans: [
      "Trust AI tokens (TRT) are available for purchase on PancakeSwap, a decentralized exchange built on the Binance Smart Chain. Visit PancakeSwap and trade BNB for TRT to join the Trust AI community.",
    ],
  },
];

type Props = {};

function FAQ({}: Props) {
  const { t } = useTranslation();
  return (
    <div id="faq" className="py-16 bg-[#000000] stopscrollmargin">
      {/* <img src="/layer/Vector152.png" className="absolute bottom-[0%] left-[-15%]  max-w-[785px] max-h-[414px]"></img> */}

      <div className="max-w-[857px] mx-auto " data-aos="fade-up" data-aos-duration="3000">
        <h1 className="text-center text-white text-4xl font-normal font-['Syne'] uppercase leading-[42.64px]">
          {t("FAQs")}
        </h1>

        <div className="pt-[118px] ">
          {faq &&
            faq.map((e: any, indx: any) => {
              return (
                <Disclosure key={indx} >
                  {({ open }) => (
                    <>
                      <Disclosure.Button
                        className={`flex border-t ${
                          open ? "text-yellow-500 border-[#D9A200]" : ""
                        }  w-full justify-between  px-6 py-8 text-left text-xl font-medium   focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75`}
                      >
                        <span
                          className={`font-normal font-Acme
                          ${open ? "text-yellow-500 " : "text-white"}`}
                        >
                          {t(e.qu)}
                        </span>
                        {/* <ChevronUpIcon
                      className={`${
                        open ? "rotate-180 transform" : ""
                      } h-5 w-5 text-white`}
                    /> */}
                      </Disclosure.Button>
                      <Disclosure.Panel className="px-4  pt-4 pb-6 text-xl text-gray-500">
                        {e.ans.map((e: any, indx: any) => {
                          return (
                            <h2 className="pb-5" key={indx}>
                              {t(e)}
                            </h2>
                          );
                        })}
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              );
            })}
        </div>
      </div>
    </div>
  );
}

export default FAQ;
