import React from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import { useMediaQuery } from 'react-responsive'

import Marquee from "react-fast-marquee";

type Props = {};

const demo_nfts = [
  { picture: "/TRUSTAITRANSPARENT2.png", name: "TRUST AI" },
  { picture: "/TRUSTAITRANSPARENT2.png", name: "Create Token" },
  { picture: "/TRUSTAITRANSPARENT2.png", name: "SWAP AI" },
  { picture: "/TRUSTAITRANSPARENT2.png", name: "STAKING AI" },
  { picture: "/TRUSTAITRANSPARENT2.png", name: "NFT AI" },
  { picture: "/TRUSTAITRANSPARENT2.png", name: "TRUST AI" },
];


function Slide_Image({}: Props) {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)'
  });
  
  const isTablet = useMediaQuery({
    query: '(min-width: 768px) and (max-width: 900px)'
  });
  return (
    <div className="py-3 md:py-[100px]">
      <div className="bg-[#DAA200]">
        <div className="w-full  bg-[#FFD600] py-5 transform rotate-[-1deg] height: 157px;">
        <Marquee
            direction="right"
            autoFill={true}
            pauseOnHover={isDesktopOrLaptop?true:false}
            play={true}
            pauseOnClick={true}
            speed={15}
          >
            {demo_nfts.map((item, i) => (
              <div
                key={i}
                className="max-w-[300px] flex items-center space-x-3 px-5"
              >
                <img
                  width={190}
                  height={190}
                  className=" w-[65px]"
                  src={item?.picture}
                  alt="Image 2"
                />
                <div className="text-black text-base md:text-2xl font-normal  leading-normal font-Aclonica">
                  {item.name}
                </div>
              </div>
            ))}
          </Marquee>
        </div>
      </div>
    </div>
  );
}

export default Slide_Image;
