import React from 'react'
import { ConnectButton } from '@rainbow-me/rainbowkit';

type Props = {}

function Connetbtn({}: Props) {
  const style = "border-[1px] text-sm md:text-base flex text-white text-center justify-center   w-full h-[41px] px-2 md:px-4 bg-transparent rounded-lg rounded-[10px] flex flex-row items-center  gap-2 border border-yellow-500 "

    return (
        <ConnectButton.Custom>
          {({
            account,
            chain,
            openAccountModal,
            openChainModal,
            openConnectModal,
            authenticationStatus,
            mounted,
          }) => {
            // Note: If your app doesn't use authentication, you
            // can remove all 'authenticationStatus' checks
            const ready = mounted && authenticationStatus !== 'loading';
            const connected =
              ready &&
              account &&
              chain &&
              (!authenticationStatus ||
                authenticationStatus === 'authenticated');
    
            return (
              <div
                {...(!ready && {
                  'aria-hidden': true,
                  'style': {
                    opacity: 0,
                    pointerEvents: 'none',
                    userSelect: 'none',
                  },
                })}
                className='rounded-lg '
              >
                {(() => {
                  if (!connected) {
                    return (
                      <button className={style} onClick={openConnectModal} type="button">
                        Connect Wallet
                      </button>
                    );
                  }
    
                  if (chain.unsupported) {
                    return (
                      <button className={style} onClick={openChainModal} type="button">
                        Wrong network
                      </button>
                    );
                  }
    
                  return (
                    <div style={{ display: 'flex', gap: 12 }}>
                
    
                      <button className={style} onClick={openAccountModal} type="button">
                        {account.displayName}
                        {/* {account.displayBalance
                          ? ` (${account.displayBalance})`
                          : ''} */}
                      </button>
                    </div>
                  );
                })()}
              </div>
            );
          }}
        </ConnectButton.Custom>
  )
}

export default Connetbtn