import React from "react";
import { Features_box } from "../../config/index";
import { useTranslation } from "react-i18next";
type Props = {};

function Features({}: Props) {
  const { t } = useTranslation();
  return (
    <div className="stopscrollmargin relative">
      <img
        src="/layer/Vector14.png"
        className="absolute bottom-[0%] left-[-15%] w-[100vw] h-[100vh]"
      ></img>

      <div className="max-w-7xl mx-auto py-[100px] p-5">
        <div className=" text-center text-yellow-500 text-sm font-semibold  leading-snug">
          FEATURES
        </div>
        <div className="pt-5 md:pt-10 text-center text-zinc-100 mb-2 text-2xl md:text-4xl font-normal font-Syne capitalize">
          {t("Create your NFT with Ai")}
        </div>

        <div className="flex flex-wrap justify-around pt-12 gap-4">
          {Features_box.map((e, indx) => {
            return (
              <div
              data-aos="fade-up"
     data-aos-anchor-placement="bottom-bottom"
     data-aos-duration="3000"
                key={indx}
                className="bg-neutral-800 w-[95%] md:w-fit  border border-yellow-500 md:px-[38px] py-[48px] rounded-2xl relative Features h-[334px]  box-border"
                style={{
                  border: "1px solid #DAA200",
                  borderBottom: "none",
                  borderLeft: "50%",
                  borderRight: "50%",
                }}
              >
                <img
                  className="mx-auto md:mx-0"
                  src={`/FEATURES/${e.iconName}.png`}
                />
                <div className="md:max-w-[210px] px-4 md:px-0 text-center md:text-start mt-[60px] text-white text-lg md:text-3xl font-bold font-Syne leading-[38px]">
                  {t(e.text)}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Features;
