import React from "react";

type Props = {
  filled: number;
  title: string;
};
//#0ddace

function LineProgress({ filled, title }: Props) {
  const filledBarStyle = {
    height: "100%",
    width: `${filled > 100 ? 100 : filled}%`,
  };

  return (
    <div>
      <div className=" text-white  text-sm md:text-xl font-bold font-['Syne'] pb-3">
        {title}
      </div>
      <div className="flex flex-row items-center gap-6">
        <div className="  h-[8px]  md:h-3  bg-[#282525] rounded-2xl flex-1">
          <div
            className="rounded-2xl overflow-hidden  bg-gradient-to-b from-yellow-400 to-yellow-500"
            style={filledBarStyle}
          ></div>
        </div>
        <div className="text-white text-sm md:text-lg font-semibold font-['Inter'] leading-[30px]">
          {filled}%
        </div>
      </div>
    </div>
  );
}

export default LineProgress;
