import React, { useEffect, useState } from "react";
import Label from "./helper/Label";
import { ethers } from "ethers";
import { Token_Type, TokenType, Admin } from "../../config/Tokencreator";
import InputSt from "./helper/InputSt";
import InputRange from "./helper/InputRange";
import ChainSelector from "./helper/ChainSelector";
import { ToastContainer, toast } from "react-toastify";
import globalService from "../../service/TrtService";
import { SendverifyContract } from "../../utils/SendVerify";
import ScaleLoader from "react-spinners/ScaleLoader";
import { Tokenmodel } from "../model/Tokenmodel";
import Connetbtn from "../Header/Connetbtn";
import {
  TokenInputcheckforTax,
  encodeConstructorArguments_basic,
  encodeConstructorArguments_tax,
  EncodeData,
  constructorArguementsMaker,
} from "../../utils/Tokenmaker";
import useTransation from "../../hooks/Token/useTransation";
import { useAccount } from "wagmi";
import { useSwitchChain } from 'wagmi'

import {
  Token_make_fees,
  Deploy_API,
  DeployAPI_TYPE,
} from "../../config/Tokencreator";
import { verifyContract, isContractVerified } from "../../utils/verifyContract";
import { Basic_contract } from "../../config/Tokencontract/basic";
import {useEthersSigner} from "../../hooks/useEthersSigner"
type Props = {};
export type TokenInfo = {
  name: string;
  symbol: string;
  decimals: string;
  totalSupply: string;
  marketingwallet: string;
  buyTax: {
    marketingFee: string;
    liquidityFee: string;
    txFee: string;
  };
  sellTax: {
    marketingFee: string;
    liquidityFee: string;
    txFee: string;
  };
};
function Tokengenarator({}: Props) {
  const { address,chain } = useAccount();
  const { chains, switchChain } = useSwitchChain()

  //state
  // token type
  const [Tokenindex, setTokenindex] = useState(TokenType.BASIC);
  // General info state
  const [openTokendeploy, setopenTokendeploy] = useState(false);
  const [deployedaddress, setdeployedaddress] = useState("");
  const [fees, setfees] = useState("0");
  const [verifyload, setverifyload] = useState(false);
  const [tokenInfo, setTokenInfo] = useState<TokenInfo>({
    name: "",
    symbol: "",
    decimals: "",
    totalSupply: "",
    marketingwallet: "",
    buyTax: {
      marketingFee: "",
      liquidityFee: "",
      txFee: "",
    },
    sellTax: {
      marketingFee: "",
      liquidityFee: "",
      txFee: "",
    },
  });
  const signer = useEthersSigner({chainId:chain?.id});

  const { loading, HandleRun, deployContract } = useTransation(signer, address);

  // Handle change for general info
  const handleGeneralInfoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setTokenInfo((prevTokenInfo) => ({
      ...prevTokenInfo,
      [name]: value,
    }));
  };
  const handleBuyTaxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setTokenInfo((prevTokenInfo) => ({
      ...prevTokenInfo,
      buyTax: {
        ...prevTokenInfo.buyTax,
        [name]: value,
      },
    }));
  };

  const handleSellTaxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setTokenInfo((prevTokenInfo) => ({
      ...prevTokenInfo,
      sellTax: {
        ...prevTokenInfo.sellTax,
        [name]: value,
      },
    }));
  };

  useEffect(() => {
    const chain_id: number = chain?.id ?? 56;
    const info = Token_make_fees[chain_id][Tokenindex];

    if (info.fees) {
      const finalfees = (info.fees / 10 ** 18).toFixed(10);
      setfees(finalfees.toString());
    }
  }, [chain?.id, address, Tokenindex]);

  const verifyContractaddress = async () => {
    if (!address) return;
     setverifyload(true);
    const chain_id: number = chain?.id ?? 56;
    const { router } = Deploy_API[chain_id];
    const { contractCode } = Token_make_fees[chain_id][Tokenindex];
    const arg = await constructorArguementsMaker(
      Tokenindex,
      tokenInfo,
      address,
      Admin,
      router
    );

     SendverifyContract(
      deployedaddress,
      arg,
      contractCode,
      address,
      chain_id,
      Tokenindex,
      tokenInfo,
      Admin,
      setopenTokendeploy
    ).then(()=>{
      setverifyload(false);
    }).catch(()=>{
      setverifyload(false);
    })
   
  };

  const Deploy = async () => {
    let Arg: any;
    const check = TokenInputcheckforTax(tokenInfo, Tokenindex);
    const chain_id: any = chain?.id ?? 56;
    const { router } = Deploy_API[chain_id];
    const { contractCode, abi, bytecode, fees } =
      Token_make_fees[chain_id][Tokenindex];

    const decimals = parseInt(tokenInfo.decimals, 10);
    const initialSupply = ethers.utils.parseUnits(
      tokenInfo.totalSupply,
      decimals
    );

    if (Tokenindex == TokenType.BASIC) {
      Arg = [
        tokenInfo.name,
        tokenInfo.symbol,
        tokenInfo.decimals,
        initialSupply,
        address,
        Admin,
      ];
    } else {
      const feeamountsBuy = tokenInfo.buyTax
        ? Object.values(tokenInfo.buyTax).map((fee: any) =>
            (Number(fee) * 100).toString()
          )
        : [];

      const feeamountsSell = tokenInfo.sellTax
        ? Object.values(tokenInfo.sellTax).map((fee: any) =>
            (Number(fee) * 100).toString()
          )
        : [];

      Arg = [
        tokenInfo.name,
        tokenInfo.symbol,
        tokenInfo.decimals,
        router,
        initialSupply,
        feeamountsBuy,
        feeamountsSell,
        tokenInfo.marketingwallet,
        address,
        Admin,
      ];
    }
    //contract has been deployed successfully
    if (check) {
      deployContract(abi.abi, bytecode, Arg, fees.toString()).then(
        async (e) => {
          if (e.isDone && e.address) {
            setopenTokendeploy(true);
            setdeployedaddress(e.address);
          }
        }
      );
    }

    if (check) {
      console.log("yes");
    }
  };

  return (
    <div
      id="token"
      className="w-[95vw] relative z-10 md:max-w-[1254px] py-10 mx-auto border bg-inputbg bg-opacity-70 rounded-2xl"
      style={{
        border: "2px solid #DAA200",
        borderBottom: "none",
        borderLeft: "50%",
        borderRight: "50%",
      }}
    >
      <Tokenmodel
        verifyNow={() => verifyContractaddress()}
        open={openTokendeploy}
        contract={deployedaddress}
        onClose={() => setopenTokendeploy(false)}
        verifyload={verifyload}
        chainid={chain?.id}
        token={tokenInfo}
      />
      <div>
        <h1 className="text-yellow-400  text-xl md:text-4xl text-center font-bold font-Syne leading-[50px]">
          Create Your Own Token
        </h1>
        <div className="px-5 md:p-10 space-y-3 md:space-y-10">
          <ChainSelector />
          {/* main content  */}
          {/* token type */}
          <div>
            <Label name="Basic Token / Tax Token " />
            <div className="grid md:grid-cols-2 gap-x-10 gap-y-3">
              {Token_Type.map((e, indx) => {
                return (
                  <button
                    onClick={() => {
                      if (!loading) {
                        setTokenindex(indx);
                      }
                    }}
                    key={indx}
                    className={` px-10 font-Aleo rounded-[5px] py-5 mx-auto flex justify-center md:mx-0 w-full text-center ${
                      indx === Tokenindex
                        ? " bg-gradient-to-r from-[#DAA200] to-[#FFD600] text-black"
                        : "text-white  bg-inputbg border-2 border-yellow-500 "
                    } `}
                  >
                    {e.name}
                  </button>
                );
              })}
            </div>
          </div>
          {/* token type */}

          {/* genaral info */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-x-10 gap-y-3 md:gap-y-5">
            <InputSt
              loading={loading}
              name="name"
              label="Name"
              value={tokenInfo.name}
              handlevaluechange={handleGeneralInfoChange}
              placeholder="Ex: Bitcoin"
            />
            <InputSt
              loading={loading}
              name="symbol"
              label="Symbol"
              value={tokenInfo.symbol}
              handlevaluechange={handleGeneralInfoChange}
              placeholder="Ex: BTC"
            />
            <InputSt
              loading={loading}
              name="decimals"
              label="Decimals"
              value={tokenInfo.decimals}
              handlevaluechange={handleGeneralInfoChange}
              placeholder="Ex: 18"
            />
            <InputSt
              loading={loading}
              name="totalSupply"
              label="Total Supply"
              value={tokenInfo.totalSupply}
              handlevaluechange={handleGeneralInfoChange}
              placeholder="Ex: 1.000.000.000"
            />
          </div>
          {/* genaral info */}

          {/* all tax info */}
          {Tokenindex == TokenType.TAX && (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-x-10 gap-y-3 md:gap-y-5 pt-5">
              <InputRange
                loading={loading}
                name="marketingFee"
                label="Marketing Fee (Buy)"
                value={tokenInfo.buyTax.marketingFee}
                handlevaluechange={handleBuyTaxChange}
              />
              <InputRange
                loading={loading}
                name="marketingFee"
                label="Marketing Fee (Sell)"
                value={tokenInfo.sellTax.marketingFee}
                handlevaluechange={handleSellTaxChange}
              />
              <InputRange
                loading={loading}
                name="liquidityFee"
                label="Liquidity Fee (Buy)"
                value={tokenInfo.buyTax.liquidityFee}
                handlevaluechange={handleBuyTaxChange}
              />
              <InputRange
                loading={loading}
                name="liquidityFee"
                label="Liquidity Fee (Sell)"
                value={tokenInfo.sellTax.liquidityFee}
                handlevaluechange={handleSellTaxChange}
              />
              <InputRange
                loading={loading}
                name="txFee"
                label="Transaction Fee (Buy)"
                value={tokenInfo.buyTax.txFee}
                handlevaluechange={handleBuyTaxChange}
              />

              {/* Similar for sell tax */}

              <InputRange
                loading={loading}
                name="txFee"
                label="Transaction Fee (Sell)"
                value={tokenInfo.sellTax.txFee}
                handlevaluechange={handleSellTaxChange}
              />
            </div>
          )}

          {/* all tax info */}

          {/* wallet info */}
          {
            <div>
              <InputSt
                loading={loading}
                name="marketingwallet"
                label="Marketing Wallet"
                value={tokenInfo.marketingwallet}
                handlevaluechange={handleGeneralInfoChange}
                placeholder="Ex: 0XFa6054DF059848949D84985162A301E56189BDE06"
              />
              <InputSt
                loading={loading}
                name=""
                label="Contract Creation Fee"
                value={fees}
                handlevaluechange={handleGeneralInfoChange}
                placeholder="0"
              />
            </div>
          }

          {/* wallet info */}

          <div className="flex justify-center pt-5 ">
            {!address && <Connetbtn />}

            {address && (
              <button
                disabled={loading}
                onClick={() => Deploy()}
                className="sButton mx-auto md:mx-0 w-fit font-bold font-Aleo hover:opacity-50  "
              >
                {!loading && "Create Token"}
                <ScaleLoader
                  height={20}
                  loading={loading}
                  color="#ffffff"
                  className="text-white"
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </button>
            )}
          </div>
          {/* main content  */}
        </div>
      </div>
    </div>
  );
}

export default Tokengenarator;
