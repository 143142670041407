export const pair = [
  {
    name: "Trust AI (TRT) ",
    pair: "0xe74957964006d46bd198656bd9f3d2a05afe9747",
    tokenImg:"TRT"
 
 
  },
  {
    name:"Bitcoin (BTC) ",
    pair:"0x61eb789d75a95caa3ff50ed7e47b96c132fec082",
    tokenImg:"Bitcoin"
  },
  {
    name:"BNB",
  
    pair:"0x85faac652b707fdf6907ef726751087f9e0b6687"
    ,tokenImg:"BNB"
  
  },
  {
    name:"Pancakeswap (Cake)",
    pair:"0x133b3d95bad5405d14d53473671200e9342896bf"
  ,  tokenImg:"Pancakeswap"
  },
  {
    name:"Ethereum (ETH)",
    pair:"0xd4dca84e1808da3354924cd243c66828cf775470"
  ,  tokenImg:"Ethereum"
  },
  {
    name:'Solana (SOL)',
    pair:"0x1e4600929edf7f36b4a7eac4c7571057d82a246c"
  ,  tokenImg:"Solana"
  }

];
