import React, { ChangeEvent } from "react";
import {
  SunIcon,
  ChevronUpIcon,
  ChevronDownIcon,
} from "@heroicons/react/24/solid";
type Props = {
  handlevaluechange: (value: any) => void;
  value: any;
};

function InputAmount({ handlevaluechange, value }: Props) {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    handlevaluechange(inputValue);


    // if (/^[0-9]*$/.test(inputValue)) {
    //   handlevaluechange(inputValue);
    // }
  };

  const handleIncrement = () => {
    handlevaluechange((Number(value) + 1).toString());
  };

  const handleDecrement = () => {
    const newValue = Math.max(Number(value) - 1, 0);
    handlevaluechange(newValue.toString());
    
  };

  return (
    <div className="relative w-full flex items-center border-2 border-r-0 border-zinc-500 rounded-xl rounded-r-none  ">
      <input
        type="text"
        title="Only numeric values are allowed."
        pattern="^[0-9]*(\.[0-9]*)?$"
        placeholder="Enter the prompt"
        className=" focus:scroll-auto    forced-colors:bg-clip-border  border-none bg-transparent focus:ring-0 focus:border-0 outline-none text-white  px-4 rounded-2xl w-full"
        value={value}
        onChange={handleChange}
      />
    </div>
  );
}

export default InputAmount;
