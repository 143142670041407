import React from "react";
import { Supply_label } from "../../config/index";
import { useTranslation } from "react-i18next";

type Props = {};

export default function Tokenomics({}: Props) {
  const { t } = useTranslation();
  return (
    <div className="stopscrollmargin max-w-7xl mx-auto py-10 md:pb-[100px] md:pt-0 relative" id="tokenomics">
      <div className="space-y-3  px-10" data-aos="fade-up" data-aos-duration="1000">
      
        <div className="pt-5 md:pt-10 text-center text-zinc-100 mb-2 text-2xl md:text-4xl font-normal font-Syne uppercase">
     {t("Tokenomics")}
      </div>
        <p className="max-w-[942px] mx-auto text-center text-zinc-500 text-base font-normal  leading-normal">
      {t("Trust AI Tokenomics is carefully thought out and structured for longevity. It ensures value is accrued for all token holders within the ecosystem. The structure is designed to allow the platform to adapt to new opportunities and challenges alike, with a strong emphasis on the community being the driving force behind its growth.")}
        </p>
      </div>
      <div className="flex flex-wrap justify-center items-center pt-10 md:pt-20 gap-10">
        {/* Image  */}
        <div data-aos="fade-right" data-aos-duration="1000" >
          <img className="w-[90%] mx-auto md:w-[294px]" src="/Image/supply.png" />
        </div>
        {/* Image  */}

        {/* content  */}
        <div data-aos="fade-left" data-aos-duration="1000"  className='space-y-[25px] pt-3 md:pt-0  w-full md:w-fit px-10 '>
          {Supply_label.map((e, indx) => {
            return (
              <div
                className="flex  flex-row gap-3 items-center text-white text-base font-normal leading-[18px]"
                key={indx}
              ><div style={{
                 background:e.color
              }} className="w-[15px] h-[15px] " />
              
               <div>
               <p>{t(`${e.share.toString()}`)}% {t(e.name.toString())}</p>
               </div>
              </div>
            );
          })}
        </div>

        {/* content  */}
      </div>
    </div>
  );
}
