import { createApi, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react'
import { TOKEN, RouteTradeParams } from '../../typeing'; // Import your types
import { getCandidatePools } from "../../utils/swap/bsc/poolget";
import { parseToken } from "../../utils/swap/bsc/Swap";
import { QUOTING_API } from "../../config/network";
import { SmartRouter } from "@pancakeswap/smart-router"; // Import SmartRouter
import ms from 'ms'
export const routeTradeApi:any = createApi({
  reducerPath: 'routeTradeApi',
  baseQuery: fetchBaseQuery(),
  tagTypes: ['data'],
  endpoints: (builder) => ({
    postRouteTrade: builder.query<any, RouteTradeParams>({
      async onQueryStarted(args: RouteTradeParams, { dispatch, queryFulfilled }) {
        // Your custom logic on query start
      },
      //@ts-ignore
      async queryFn(args, _api, _extraOptions, fetch) {
      
        if(!args) return;
        
        const { inputCurrency, outputCurrency, chainId, currencyAmount, TradeType } = args;

        if (!inputCurrency || !outputCurrency) {
          console.error("Input or output currency is undefined");
          return;
        }

    
    
        const A = parseToken(inputCurrency, chainId);
        const B = parseToken(outputCurrency, chainId);

        try {
          const pools: any = await getCandidatePools({
            currencyA: A,
            currencyB: B,
            chainId: chainId,
          });

          const data = {
            currencyAmount: currencyAmount,
            tradeType: TradeType,
            currency: {
                INPUT: inputCurrency,
                OUTPUT: outputCurrency,
              },
            pools: pools.map(SmartRouter.Transformer.serializePool),
          };

          const response = await fetch({
            method: 'POST',
            url: QUOTING_API,
            body: (data),
          })
          console.log(response,"a");
          
  

          const serializedRes = await response.data;
          //   const res = SmartRouter.Transformer.parseTrade(
          //     chainId,
          //     serializedRes.route
          //   );
          console.log(serializedRes,"serializedRess");
          
          if (response.error) {
            return {data:[]}
          }
          return {
            data:serializedRes
          };
        } catch (error) {
          console.error("Error in Route:", error);
        }
      
    
    },
    //   keepUnusedDataFor: ms(`10s`),
      

    }),
  }),
});

export const { usePostRouteTradeQuery } = routeTradeApi;
