import React, { useEffect, useState, useRef, ChangeEvent } from "react";
import InputAmount from "./Helper/InputAmount";
import {
  ArrowPathIcon,
  ArrowDownIcon,
  ArrowDownTrayIcon,
  PlusIcon,
} from "@heroicons/react/20/solid";
import useLogin from "../../../hooks/Nft/useLogin";
import GenerateButton from "./Helper/GenerateButton";
import { useAccount, useSignMessage } from "wagmi";
import LazyloadImage from "../../Imagecom/nft/LazyloadImage";
import PuffLoader from "react-spinners/PuffLoader";
import { Storenfttoipfs } from "../../../data/nft/genarateslice";
import { Mintnft } from "../../model/Mintnft";
import useUploadnft from "../../../hooks/Nft/useUploadnft";
import { MIN_TOKEN_HOLD } from "../../../config/nftmint/index";
import toast, { Toaster } from "react-hot-toast";
import { ConvertLink } from "../../../utils/convertipfs";
import { Signmodel } from "../../model/Signmodel";

import {
  MINT_CONTRACT,
  MINT_COST,
  NATIVE_TOKEN,
  PICTURE_COST,
  tag_search,
} from "../../../config/nftmint/index";
import {useEthersSigner} from "../../../hooks/useEthersSigner"
type Props = {};

function NFTminterhelper({}: Props) {
  const { address ,chain} = useAccount();
  // state
  const [artPrompt, setartPrompt] = useState("");
  const [mintmodel, setmintmodel] = useState(false);
  const [signinmodel, setsigninmodel] = useState(false);
  const [stepindex, setstepindex] = useState(0);
  const [nftinfo, setnftinfo] = useState({
    name: "",
    description: "",
  });
  const [isLocalImg, setisLocalImg] = useState(false);
  const localImageref: any = useRef(null);
  const [fileImg, setFileImg] = useState<File | null>(null);

  const  signer  = useEthersSigner({chainId:chain?.id});
  // const [pictureloaded]

  //hook
  const {
    Loginuser,
    signin,
    login,
    loading,
    imageurl,
    genarateImage,
    genarateload,
    ipfsuri: Imageifps,
    addImage,
  } = useLogin();

  const {
    Storenft,
    HandleRun,
    loading: uploadload,
    ipfsurl,
    checkLimit,
    balance,
    UploadLocalImage,
  } = useUploadnft(signer, address, chain?.id);
  // func to update artPrompt
  const handleChange = (e: string) => {
    setartPrompt(e);
  };

  const handleClick = () => {
    localImageref.current.click();
  };

  useEffect(() => {
    if (!address) return;
    if (!signin && loading == "error") {
      setsigninmodel(true);
    } else {
      setsigninmodel(false);
    }
  }, [signin, loading, address]);

  // login user
  const loginUser = () => {
    Loginuser();
  };

  const GenarateNFT = async () => {
    if (artPrompt == "") return;
    if (uploadload) return;
    if (!signin) {
      toast.error("please sign in to your wallet to access our dapp.");
      setsigninmodel(true);
      return;
    }

    const isHold_token = Number(MIN_TOKEN_HOLD) <= Number(balance);
    if (!isHold_token) {
      // toast.error("You need to hold a minimum of 500 TRT to generate images.");
      // return;
    }
    // we will call our api and check if he able to with free or not...
    const _limit = await checkLimit();
    const data = {
      prompt: artPrompt,
    };

    if (_limit) {
      genarateImage(data);
    } else {
      // take fees bro...
      const _chainid = chain?.id ?? 0;
      HandleRun(
        "buyCredits",
        [],
        "Initiating image generation process. Please wait...",
        "image generation started..",
        PICTURE_COST[_chainid]
      ).then((e) => {
        if (e?.isDone) {
          genarateImage(data);
        }
      });

      genarateImage(data);
    }
    setisLocalImg(false);
  };

  const handleInfoChange = (fieldName: string, value: string) => {
    setnftinfo((prevInfo) => ({
      ...prevInfo,
      [fieldName]: value,
    }));
  };

  const Mintcheck = () => {
    if (!address) return;
    if (imageurl == "") return;
    if (!signin) return;
    setmintmodel(true);
  };

  const Mint = async () => {
    if (!address) return;
    if (!signin) return;
    const _chainid = chain?.id ?? 0;
    const ipfsurlAsString = String(ipfsurl);
    HandleRun(
      "Mint",
      [ipfsurlAsString],
      "Minting in progress...",
      "Minting completed successfully",
      MINT_COST[_chainid]
    ).then((e) => {
      if (e?.isDone) {
        setmintmodel(false);
        setstepindex(0);
      }
    });
  };

  const storenftformetadata = async () => {
  
    if (!nftinfo.name.trim() || !nftinfo.description.trim()) {
      // Display an error message or handle the validation failure
      toast.error("Name and description cannot be empty");
      return;
    }
    //take image from local image if localimage true ..
    if (isLocalImg) {
      const uploadLocal = await UploadLocalImage(fileImg);

      const isdone = await Storenft(uploadLocal, nftinfo);
      if (isdone) {
        setstepindex(1);
      }

      // const uploadLocal = UploadLocalImage("")
    } else {
      const isdone = await Storenft(Imageifps, nftinfo);
      if (isdone) {
        setstepindex(1);
      }
    }
  };

  const handleDownload = async () => {
    if (Imageifps !== "") {
      const toastId = toast.loading("Downloading image from ipfs...");

      try {
        const _url = ConvertLink(Imageifps);
        const response = await fetch(_url);
        const imageblob = await response.blob();

        const anchor = document.createElement("a");
        anchor.href = URL.createObjectURL(imageblob);
        anchor.download = "image.png";

        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);

        toast.success("Image downloaded successfully!", {
          id: toastId,
          duration: 3000, // Adjust duration as needed
        });
      } catch (error) {
        console.error("Error downloading image:", error);
        toast.error("Failed to download image. Please try again.", {
          id: toastId,
          duration: 3000, // Adjust duration as needed
        });
      }
    }
  };

  const handleImageChangeaa = (event: ChangeEvent<HTMLInputElement>) => {
    // Access the selected file using event.target.files
    const selectedFile = event.target.files?.[0];

    // Add your logic for handling the selected file
    if (selectedFile) {
      console.log("Selected file:", selectedFile);
      const image = URL.createObjectURL(selectedFile);
      addImage(image);
    }
  };
  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      const imgname = file.name;
      const reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onloadend = () => {
        const img = new Image();
        img.src = reader.result as string;

        img.onload = () => {
          const canvas = document.createElement("canvas");
          const maxSize = Math.max(img.width, img.height);

          canvas.width = maxSize;
          canvas.height = maxSize;

          const ctx = canvas.getContext("2d");

          if (ctx) {
            ctx.drawImage(
              img,
              (maxSize - img.width) / 2,
              (maxSize - img.height) / 2
            );

            canvas.toBlob(
              (blob) => {
                if (blob) {
                  const newFile = new File([blob], imgname, {
                    type: "image/png",
                    lastModified: Date.now(),
                  });
                  setFileImg(newFile);
                  const image = URL.createObjectURL(newFile);
                  addImage(image);

                  console.log(newFile);
                  // setImage(newFile);
                }
              },
              "image/jpeg",
              0.8
            );
            setisLocalImg(true);
          }
        };
      };
    }
  };

  return (
    <div
      className="max-w-[550px] stopscrollpadding mx-auto px-5 md:px-10 rounded-3xl pb-10 z-10 relative"
      style={{
        border: "2px solid #DAA200",
        borderBottom: "none",
        borderLeft: "50%",
        borderRight: "50%",
      }}
    >
      <Mintnft
        open={mintmodel}
        mintnft={() => Mint()}
        uploadmetadata={() => storenftformetadata()}
        stepindex={stepindex}
        onClose={() => setmintmodel(false)}
        loading={uploadload}
        onInfoChange={handleInfoChange}
      />

      <div className="text-white py-5 text-center text-xs md:text-sm">
        {`Enjoy the first 5 image generations for free! Beyond this limit, a fee of $0.1 per image applies, payable with ${
          NATIVE_TOKEN[chain?.id || 56]
        }. Create and explore, and feel free to reach out for support. Happy generating!`}
      </div>
      {/* input and promit */}
      <div className="flex flex-row   bg-opacity-5 rounded-[5px]">
        <InputAmount value={artPrompt} handlevaluechange={handleChange} />
        <GenerateButton
          connetwallet={address != undefined}
          onClickSignin={() => loginUser()}
          signin={signin}
          loading={genarateload == "pending" || loading == "pending"}
          onClickGenerate={GenarateNFT}
        />
      </div>
      {/* input and promit */}

      <div className="flex flex-wrap gap-3 pt-3 ">
        {tag_search.map((e, index) => {
          return (
            <button
              onClick={() => handleChange(e)}
              className="px-2 py-1 text-xs flex items-center text-white border border-opacity-50 border-[#DAA200] rounded-lg "
              key={index}
            >
              {e}
            </button>
          );
        })}
      </div>

      {/* generated image will show here  */}
      {/* <LazyloadImage  src={"https://oaidalleapiprodscus.blob.core.windows.net/private/org-7pKfDV3DttfWywUgHtJkwREN/user-W7LUZ3LG26x7uyj8XBDIccoj/img-WeQRmUMIrqxGlp7eZkuk6eBP.png?st=2024-01-31T14%3A55%3A55Z&se=2024-01-31T16%3A55%3A55Z&sp=r&sv=2021-08-06&sr=b&rscd=inline&rsct=image/png&skoid=6aaadede-4fb3-4698-a8f6-684d7786b067&sktid=a48cca56-e6da-484e-a814-9c849652bcb3&skt=2024-01-31T05%3A54%3A06Z&ske=2024-02-01T05%3A54%3A06Z&sks=b&skv=2021-08-06&sig=LYy/MZzXKfmyQoRzcbVFhDy6bnyMVzEdu4dfdetnxZw%3D"} /> */}
      {genarateload == "pending" ? (
        <div className="text-center mt-10 w-full aspect-square flex items-center justify-center">
          <div className="text-[#e8b10e] text-center flex justify-center flex-col">
            {" "}
            <PuffLoader className="mx-auto" color="#e8b10e" /> Please wait..
          </div>{" "}
        </div>
      ) : imageurl === "" ? (
        <div
          style={{
            border: "2px solid #DAA200",
            borderBottom: "none",
            borderLeft: "50%",
            borderRight: "50%",
          }}
          className="w-full flex flex-col items-center justify-center  aspect-square	  mx-auto mt-10  rounded-3xl"
        >
          <div className="p-3 space-y-8 ">
            <img
              src="/logo.png"
              className="mx-auto w-[50%] px-5 md:max-w-[200px] "
            />
            <img className="px-2 md:px-5" src="/Nft/AIIMAGEGENERATE.svg" />
          </div>
        </div>
      ) : (
        <div className="mx-auto flex justify-center py-5 mt-2">
          <LazyloadImage src={ConvertLink(imageurl)} />
        </div>
      )}

      {/* <LazyloadImage src={imageurl} /> */}

      {/* generated image will show here  */}

      {/* hidden inout for upload  */}
      <input
        id="image-upload-input"
        type="file"
        accept="image/*"
        onChange={handleImageChange}
        ref={localImageref}
        style={{ display: "none" }}
      />
      {/* hidden inout for upload  */}
      {/* miting part  */}

      <div className="flex justify-center py-8 gap-7  ">
        <button
          onClick={() => GenarateNFT()}
          disabled={genarateload == "pending"}
        >
          {" "}
          <ArrowPathIcon className="h-6 cursor-pointer w-6 text-center  text-yellow-400" />
        </button>

        <button onClick={() => handleDownload()}>
          <ArrowDownTrayIcon className="h-6 cursor-pointer w-6 text-center  text-yellow-400" />
        </button>
        <button onClick={() => handleClick()}>
          <PlusIcon className="h-6 cursor-pointer w-6 text-center  text-yellow-400" />
        </button>
      </div>

      <div className="grid grid-cols-2 max-w-[600px] mx-auto gap-5">
        <button className="text-yellow-400 items-center t px-3 md:px-10 text-xs md:text-base border border-yellow-500 bg-transparent text-center flex justify-center  rounded-xl w-full   cursor-pointer mx-auto md:mx-0 font-bold font-Aleo ">
          Price: {(Number(MINT_COST[chain?.id || 56]) / 10 ** 18).toFixed(4)}{" "}
          {NATIVE_TOKEN[chain?.id || 56]}
        </button>

        <button
          onClick={() => Mintcheck()}
          className="sButton px-3 md:px-10  text-xs md:text-base  text-center  flex justify-center    rounded-xl w-full   cursor-pointer mx-auto md:mx-0 font-bold font-Aleo text-neutral-900"
        >
          Mint Now
        </button>
      </div>

      {/* miting part  */}
    </div>
  );
}

export default NFTminterhelper;
