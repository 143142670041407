import React from "react";
import { useTranslation } from "react-i18next";
import LineProgress from "./Helper/LineProgress"
type Props = {};

function Practice({}: Props) {
  const { t } = useTranslation();
  return (
    <div className="w-full md:max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-2  px-5 gap-5 py-10">
      {/* text */}
      <div className="space-y-3">
        <div className=" text-yellow-400 text-center md:text-start text-sm font-semibold font-['Inter'] leading-snug">
          OUR PRACTICE
        </div>
        <div className=" text-white text-2xl md:text-5xl text-center md:text-start font-bold font-['Syne']">
          Our strength
        </div>
        <p className="w-full pt-3 md:max-w-[460px] text-neutral-50 text-center md:text-start text-sm md:text-base font-normal font-['Inter'] leading-relaxed">
          {t(
            "Our strength lies in pioneering AI technology that guarantees uniqueness for every NFT, fostering creativity and individuality in the digital art space. We empower users and artists to enhance NFTs through our innovative platform. Additionally, our upcoming fully functional NFT marketplace will provide a seamless ecosystem for minting, selling, and buying, positioning us at the forefront of the evolving digital art landscape."
          )}
        </p>
      </div>
      {/* text */}

      {/* progress */}
      <div className="space-y-5 mt-5 md:mt-10">
        <LineProgress title="Design" filled={85} />
        <LineProgress title="Technology" filled={90} />
        <LineProgress title="Mint" filled={70} />
      </div>
      {/* progress */}
    </div>
  );
}

export default Practice;
