import React from "react";
import Hero from "../../components/Nftmint/Home/Hero";
import Welcome from "../../components/Nftmint/Home/Welcome";
import Practice from "../../components/Nftmint/Home/Practice";
import Systeam from "../../components/Nftmint/Home/Systeam";
import Features from "../../components/Nftmint/Home/Features";
import Topnft from "../../components/Nftmint/Home/Topnft";
import FAQ  from "../../components/Nftmint/Home/FAQ"
import NFTminter from "../../components/Nftmint/Home/NFTminter"

type Props = {};

function Nftmint({}: Props) {
  return (
    <div>
      <div className="overflow-x-hidden mt-[-200px] bg-bggray  ">
        <Hero />
        <Welcome />
        <Practice />
        <div className='relative'>
        <img className="w-[100vw] opacity-25  m-auto bottom-0 absolute left-0 right-0 z-[0] top-[10%] " src="/layer/Vector154.png" />
        <NFTminter/>
        </div>
        <Systeam />
        <Features />
        <Topnft/>
        <FAQ/>
      </div>
    </div>
  );
}

export default Nftmint;
