import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
type Props = {};

function Nft({}: Props) {
  const { t } = useTranslation();

  return (
    <section  id="nftai"
      className="stopscrollmargin max-w-7xl max-h-[100vh] mx-auto py-10 md:py-[100px] items-center gap-10 flex flex-col md:flex-row"

    >
      {/* image  */}

      <div
        className="w-[90%] mx-auto md:w-[60%] h-full "
        id="nftAi"
        data-aos="fade-right"
        data-aos-duration="1000"
      >
        <img className=" rounded-[20px] " src="/Nft/4.png" />
      </div>

      {/* image  */}

      {/* content  */}
      <div
        className="text-start px-5 md:px-0 "
        data-aos="fade-left"
        data-aos-duration="1000"
     
      >
        <div className=" text-yellow-500 text-sm font-normal   leading-snug">
          NFT AI
        </div>
        <div className=" pt-5 md:pt-10  text-zinc-100 mb-2 text-2xl md:text-5xl  font-normal font-Syne capitalize leading-5">
          {t("Mint / Generate NFT’s")}
        </div>
        <div className="md:px-0 max-w-[470px] opacity-80 text-zinc-500 text-base font-normal leading-relaxed">
          {t(
            "Experience the future of digital art with our AI-powered NFT minting process, creating unique and mesmerizing collectibles."
          )}
        </div>
        <Link    to="/nftai" className="sButton mt-5 mx-auto md:mx-0 w-fit ">
          <div className="bText">{t("Mint Now")}</div>
        </Link>
      </div>
      {/* content  */}
    </section>
  );
}

export default Nft;
