import React from "react";
import { Link as SLink } from "react-scroll";

type Props = {};

function Staticsection({}: Props) {
  return (
    <div className="min-h-[calc(100vh-100px)] max-[800px]:min-h-[calc(100%-100px-120px)] pb-10 mb-[-10px] pt-5 bg-opacity-20 justify-center bg-cover bg-center custom-bg relative">
      {/* Overlay with gradient */}
      <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-b from-[#0000004d] to-[#000000]"></div>

      <div className="space-y-5 px-5 text-center pt-20 relative z-10 ">
        <div className="text-center text-zinc-100 text-3xl md:text-6xl md:font-semibold font-Syne uppercase">
          Create Token
        </div>
        <div className="max-w-[584px] mx-auto text-center text-white text-base font-medium font-Inter leading-normal">
          To create a token, simply ensure you have Deploy fees in your wallet.
          With these fees, you can swiftly and securely generate your custom
          token. Refer to the table below for a seamless process.
        </div>
        <div className="flex justify-center">
          <SLink
            smooth={true}
            to="token"
            className="sButton cursor-pointer mx-auto md:mx-0 w-fit font-bold font-Aleo text-neutral-900"
          >
            Get Started
          </SLink>
        </div>
      </div>

      {/* Background images */}
      <img
        className="absolute hidden md:block bottom-0 m-auto left-0 right-0 w-full md:max-w-[1280px]"
        src="/createToken/MediumSizehero.svg"
      />
      <img
        className="absolute hidden md:block top-[20%] m-auto left-0 right-0 w-full md:max-w-[800px]"
        src="/layer/Vector153.png"
      />
    </div>
  );
}

export default Staticsection;
