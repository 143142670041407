import React from "react";
import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon ,PlusIcon,MinusIcon} from "@heroicons/react/20/solid";
import { useTranslation } from "react-i18next";
const faq = [
  {
    qu: "What it takes to create an image?",
    ans: [
      "Ready to craft some visual magic? All it takes is your creative flair and a sprinkle of these deets",
    ],
  },
  {
    qu: "What's the standard image size?",
    ans: [
      "🖼️ Standard Image Size: 1024 pixels – the perfect canvas!",
    ],
  },
  {
    qu: "Payment methods",
    ans: [
      "💸 Payment Methods: Hold 500 TRT and unleash the magic for just 1$ in BNB.",
    ],
  },
  {
    qu: "Help Support",
    ans: [
      "🚀 Help Support: Our Trust AI team – your creative sidekick!",
    ],
  },
  {
    qu: "Can i create multiple images at once?",
    ans: [
      "And guess what? It's a one-image party, but oh boy, it's going to be a showstopper! Get ready to rock the art scene! 🌟🎨✨",
    ],
  },

];

type Props = {};

function FAQ({}: Props) {
  const { t } = useTranslation();
  return (
    <div id="faq" className="py-16 stopscrollpadding">
      {/* <img src="/layer/Vector152.png" className="absolute bottom-[0%] left-[-15%]  max-w-[785px] max-h-[414px]"></img> */}

      <div className="max-w-[857px] space-y-3 mx-auto " data-aos="fade-up" data-aos-duration="3000">
     
      <div className=" text-center text-yellow-400 text-sm font-semibold font-['Inter'] leading-snug">
          {t("FAQ")}
        </div>
        <div className="w-[90vw] md:max-w-[550px] leading-[4rem] 	 mx-auto text-center text-white text-2xl md:text-[3rem] font-bold font-['Syne']">
          {t("AI image generator FAQs.")}
        </div>


        <div className="pt-[118px] ">
          {faq &&
            faq.map((e: any, indx: any) => {
              return (
                <Disclosure key={indx} >
                  {({ open }) => (
                    <>
                      <Disclosure.Button
                        className={`flex border-t border-neutral-700 ${
                          open ? "text-yellow-500 border-[#D9A200]" : ""
                        }  w-full justify-between   px-6 py-8 text-left text-xl font-medium   focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75`}
                      >
                        <span
                          className={`font-normal font-Acme
                          ${open ? "text-yellow-500 " : "text-white"}`}
                        >
                          {t(e.qu)}
                        </span>
                   {open? <MinusIcon
                      className={`${
                        open ? "rotate-180 transform" : ""
                      } h-5 w-5 text-white`}
                    /> : <PlusIcon
                    className={`${
                      open ? "rotate-180 transform" : ""
                    } h-5 w-5 text-white`}
                  />}
                      </Disclosure.Button>
                      <Disclosure.Panel className="px-4  pt-4 pb-6 text-xl text-gray-500">
                        {e.ans.map((e: any, indx: any) => {
                          return (
                            <h2 className="pb-5" key={indx}>
                              {t(e)}
                            </h2>
                          );
                        })}
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              );
            })}
        </div>
      </div>
    </div>
  );
}

export default FAQ;
