import React,{useEffect} from "react";
import Swapform from "../../components/Swap/Swapform";
import Staticsection from "../../components/Swap/Staticsection";

type Props = {};

function Swap({}: Props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="">
      <div className=" font-ChakraPetch ">
        <Staticsection />
      </div>

      <div className="relative py-5 pb-20 bg-bggray  "> 
     
          <img className="max-w-[100vw] hidden md:block m-auto absolute left-0 right-0 z-[0] top-[-40%] " src="/layer/Vector153.png" />
   
  


        <div className="">
        <Swapform />
        </div>
      </div>
    </div>
  );
}

export default Swap;
