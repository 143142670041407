// ImageTextComponent.tsx

import React from "react";

interface ImageTextComponentProps {
  imageUrl: string;
  text1: string;
  text2: string;
  link:string,
  bgColor:string
}

const ImageTextComponent: React.FC<ImageTextComponentProps> = ({
  imageUrl,
  text1,
  text2,
  link,
  bgColor
}) => {
  return (
    <div data-aos="fade-up" data-aos-easing="ease-in-out" data-aos-duration="2000" className=" bg-zinc-900 rounded-2xl"    style={{
      border: "1px solid #DAA200",
      borderBottom: "none",
      borderLeft: "50%",
      borderRight: "50%",
    }}>
    <div className="px-6 py-8">
      <div style={{
        background:bgColor
      }} className="bg-[#2B6AFF] px-5 flex items-center h-36 py-10 md:py-0 rounded-xl">
        {" "}
        <img src={imageUrl} alt="Image" className="rounded-xl" />
      </div>
      <div className=" ">
        <p className=" text-zinc-100 text-2xl font-normal py-3  leading-loose font-Acme">
          {text1}
        </p>
     
        <a href={link} target="_blank" rel="noopener noreferrer" className="sButton mt-5 py-3 px-6">
          <div className="bText">{text2}</div>
        </a>
      </div>
      </div>
    </div>
  );
};

export default ImageTextComponent;
