import React, { useState, useEffect } from "react";

import toast, { Toaster } from "react-hot-toast";
import {
  getTokeninstance,
  getContractInstanceSigner,
  ConvertEthTonormal,
  getTokeninstanceBysigner,
} from "../../utils/contracthelper";

import { ethers } from "ethers";
//buy contract  - contractaddress
import {  multicall } from "@wagmi/core";

const useTransation = (signer: any, account: any) => {
  const [loading, setSellTokenLoading] = useState(false);
  const [balance, setbalance] = useState("0");
  // check approval amount for user
  // check user balance

  const CheckBalance = async () => {
    try {
      setSellTokenLoading(false);
    } catch {}
  };

  useEffect(() => {
    if (account != undefined && signer != undefined) {
      CheckBalance();
    }
  }, [account, signer]);

  const deployContract = async (
    contractABI: any,
    contractBytecode: any,
    args: Array<any>,
    fees: string
  ) => {
    const toastId = toast.loading("deploying...");

    const contractFactory = new ethers.ContractFactory(
      contractABI,
      contractBytecode,
      signer
    );
    console.log(args);

    try {
      setSellTokenLoading(true);
      const response = await contractFactory.deploy(...args, {
        value: fees,
      });
      const deploy = await response.deployed();
      console.log(`Contract deployed at address: ${response.address}`);
      setSellTokenLoading(false);
      toast.success("Congratulations! Contract deployed!", {
        id: toastId,
      });

      return { isDone: true, error: null, address: response.address };
    } catch (error: any) {
      console.log(error, "error");

      setSellTokenLoading(false);
      if (error.code == "ACTION_REJECTED") {
        toast.error("User Cancelled The Transaction", {
          id: toastId,
        });
      } else if (error.reason) {
        toast.error(error.reason || "Something went wrong try again", {
          id: toastId,
        });
      } else if (error.data) {
        if (error.data.code == -32000) {
          toast.error(
            "Insufficient funds. Please ensure you have a minimum of $30 in your wallet for transaction fees.",
            {
              id: toastId,
            }
          );
        } else {
          toast.error("Something went wrong try again", {
            id: toastId,
          });
        }
      }

      return { isDone: false, error: "Contract deployment failed!" };
    }
  };

  // run buy func .
  const HandleRun = async (
    tokenname: string,
    fname: string,
    args: Array<any>,
    type: string,
    ABI: any,
    contract: any,
    fees: string
  ) => {
    let address: any;
    const name = String(fname);
    setSellTokenLoading(true);

    try {
      const myContract = await getContractInstanceSigner(contract, ABI, signer);
      const contractInterface = new ethers.utils.Interface(ABI.abi);

      const response = await myContract?.[name](...args, {
        value: fees, // Replace "0.1" with the actual amount you want to send
      });
      const receipt = await response.wait();
      const abiCoder = new ethers.utils.AbiCoder();
      console.log(receipt);
      const getAddress = receipt.logs.map((e: any) => {
        try {
          // const decodedParameters = abiCoder.decode(["string"], e);
          const decodedParameters = abiCoder.decode(
            ["address", "string"],
            e.data
          );
          if (tokenname == decodedParameters[1]) {
            address = decodedParameters[0];
          }
          console.log(decodedParameters);
        } catch {}
      });

      toast.success(`Congratulations! ${type}`);
      setSellTokenLoading(false);
      return { isDone: true, contract: address };
    } catch (error: any) {
      console.log("error");

      if (error.code == "ACTION_REJECTED") {
        toast.error("User Cancelled The Transaction");
      } else {
        toast.error(error.reason || "Something went wrong try again");
      }

      setSellTokenLoading(false);
      console.log(error);
      return { isDone: false };
    }
  };

  return { loading, HandleRun, balance, deployContract };
};

export default useTransation;
