import { configureStore } from '@reduxjs/toolkit';
import Poolslice from './Poolslice';
import Swapslice from "./swapslice";
import Nftslice from "./Nftslice";
import { routeTradeApi } from '../data/swap/rtkRouteslice'; // Import your RTK Query API
import { setupListeners } from '@reduxjs/toolkit/query'

const store = configureStore({
  reducer: {
    pool: Poolslice,
    swapState: Swapslice,
    Nftslice:Nftslice,
    [routeTradeApi.reducerPath]: routeTradeApi.reducer, // Add the RTK Query reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(routeTradeApi.middleware), // Add the RTK Query middleware
});

// Set up RTK Query listeners
setupListeners(store.dispatch);

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
