import React from "react";
import { FeaturesContent } from "../../../config/nftmint/index";
import Featuresbox from "./Helper/Featuresbox";
type Props = {};

function Features({}: Props) {
  return (
    <div className="w-full md:max-w-7xl mx-auto py-24">
         <div className=" text-center text-white text-2xl md:text-5xl font-bold font-['Syne']">
         Features
      </div>
      <div className=" mx-auto flex flex-wrap max-[1200px]:gap-10 px-5    min-[1210px]:grid-cols-3 pt-10 md:pt-24 max-w-[1210px]  ">
        {FeaturesContent.map((e, indx) => {
          return (
            <Featuresbox
              key={indx}
              index={indx}
              title={e.title}
              logo={e.logo}
              dec={e.full}
            />
          );
        })}
      </div>
    </div>
  );
}

export default Features;
