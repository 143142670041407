import { createAsyncThunk } from "@reduxjs/toolkit";
import { ethers } from "ethers";
import { getContractInstance, Erc20Balance } from "../../utils/contracthelper";
import { pools,CHAIN_ID_STAKING } from "../../config/Pool";
import { getPoolData } from "../../utils/poolHelper";
import ercabi from "../../abi/erc20.json";

export const getALLpool = createAsyncThunk(
  "getAllPoolupdate",
  async (parms: { user: string | any }, { dispatch, rejectWithValue }) => {
    try {
      const promises = pools.map(async (pool) => {
        const instance = await getContractInstance(
          pool.contract,
          pool.ABI,
          CHAIN_ID_STAKING
        );
        let stakeTokenbalance = "0";

        const { staked, totaldeposit } =
          (await getPoolData(
            pool.poolType,
            instance,
            parms.user,
            pool.stakingTokenDecimals
          )) || {};

        if (parms.user) {
          try {
            stakeTokenbalance = await Erc20Balance(
              pool.staketoken,
              ercabi,
              CHAIN_ID_STAKING,
              parms.user
            );
          } catch (e) {}
        }

        return {
          ...pool,
          totaldeposit: totaldeposit,
          poolloading: false,
          staked: staked,
          user:parms.user,
          stakingTokenBalance: stakeTokenbalance,
        };
      });
      const updatedPools = await Promise.all(promises);

      return { updatedPools };
    } catch (e: any) {
      console.log(e);
      return rejectWithValue({
        updatedPools: [],
      });
    }
  }
);
