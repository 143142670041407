import { GraphQLClient, gql } from "graphql-request";
import { endpoint } from "../../config/Pool";
import { request } from "graphql-request";
export interface StakedData {
  id: string;
  user: string;
  poolId: number;
  stakeId: number;
  endTime: number;
  end: boolean;
  amount: number;
  blockNumber: number;
  blockTimestamp: number;
  startTime: number;
}
export interface Returndata {
 data:StakedData[]
}

export const fetchStakedData = async (user: string): Promise<Returndata> => {
  const query = gql`
    query GetStakedData($user: String!) {
      activestake: stakeds(first: 1000, where: { end: false, user: $user }) {
        id
        user
        poolId
        stakeId
        endTime
        end
        amount
        blockNumber
        blockTimestamp
        startTime
      }

      stakeEnds: stakeds(first: 1000, where: { end: true, user: $user }) {
        id
        user
        poolId
        stakeId
        endTime
        end
        amount
        blockNumber
        blockTimestamp
        startTime
      }
    }
  `;

  // const stakedata = await request<DataResults>(
  //   TokendataClient[id],
  //   STAKE_INFO,
  //   variables
  // );
  const variables = { user };
  const data = await request<{
    activestake: StakedData[];
    stakeEnds: StakedData[];
  }>(endpoint,query, variables);
 ;
  

  return {
    data:[...data.activestake,...data.stakeEnds]
  };
};
