import React from "react";

import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import Marquee from "react-fast-marquee";
import { Tagslide } from "../../../config/nftmint/index";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link as SLink, animateScroll as scroll } from "react-scroll";
import { NavHashLink } from "react-router-hash-link";
type Props = {};

function Hero({}: Props) {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });

  const isTablet = useMediaQuery({
    query: "(min-width: 768px) and (max-width: 1223px)",
  });

  const { t } = useTranslation();

  return (
    <div
      id="/"
      className="min-h-screen flex items-center md:items-start justify-center  bg-cover bg-center custom-bg relative py-10 md:pt-[200px]   "
    >

         {/* Overlay with gradient */}
         <div className="absolute opacity-75 top-0 left-0 w-full h-full bg-gradient-to-b from-[#0000004d] to-[#000000]"></div>
      {/* <div className="absolute right-0 top-0">
        <img className="w-[100vw] h-[100vh]" src="/homegradient.png" />
      </div> */}
      {/* main text */}

      <div className=" z-10  max-[350px]:max-w-[300px] max-[767px]:max-w-[360px] md:pt-[4vh]">
        <div className=" space-y-5  ">
          <h1
            data-aos="fade-up"
            data-aos-duration="1000"
            className="text-3xl font-Syne textShineSlow max-w-[800px] mx-auto   md:text-6xl  text-white text-center font-bold"
          >
            {t("Generate image with Trust AI")}
          </h1>
          <p
            data-aos="fade-up"
            data-aos-duration="1000"
            className="text-xl text-white text-center font-semibold "
          >
            {t("Create Anything - AI With No Restrictions.")}
          </p>
         <div className="flex justify-center relative z-10">
         <SLink
            smooth={true}
            to="nftminter"
            className="sButton cursor-pointer mx-auto md:mx-0 w-fit font-bold font-Aleo text-neutral-900"
          >
         {t("Generate NFT")}
          </SLink>
         </div>

          <div className="">
            <LazyLoadImage
              className="absolute hidden md:block w-[100%] h-auto bottom-[10%] left-0 right-0"
              src="/Nft/hero.webp"
            />
              <LazyLoadImage
              className="absolute block md:hidden w-[100%] bottom-[10%] left-0 right-0"
              src="/Nft/hero_600px.png"
            />
            <div className="absolute w-full h-[22vh] md:h-[30vh] bottom-0 left-0 overlaybgfornft"></div>
          </div>
          <div className=" absolute w-[100vw] bottom-[2%] left-0 right-0 h-[5vh] flex justify-end">
            <Marquee
              direction="right"
              autoFill={true}
              pauseOnHover={isDesktopOrLaptop ? true : false}
              play={true}
              pauseOnClick={true}
              speed={15}
            >
              {Tagslide.map((e, indx) => (
                <div
                  key={indx}
                  className="max-w-[210px] mx-3 h-[34px] px-4 py-1.5 bg-neutral-800 rounded-[100px] justify-start items-start gap-2.5 inline-flex"
                >
                  <div className="text-center text-white text-sm font-semibold font-['Inter'] leading-snug">
                    {e}
                  </div>
                </div>
              ))}
            </Marquee>
          </div>
        </div>
      </div>

      {/* main text */}
    </div>
  );
}

export default Hero;
