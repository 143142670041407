import TokenABI from "../abi/Basic.json";
import TAXabi from "../abi/Tax.json";
import { Basic_contract } from "../config/Tokencontract/basic";
import {Token_make_bytecode }from "./Tokencontract/bytecode"
import { TAX_TOKEN } from "../config/Tokencontract/tax";
export const Admin = "0x46222Ff2e74c9a96aCC3Ca3A8619BB324724E37D";

export const Token_Type = [
  {
    name: "Basic Token",
  },
  {
    name: "Tax Token",
  },
];

export enum TokenType {
  BASIC,
  TAX,
}

type TokenInfo = {
  fees: number;
  abi: any; // Replace with the actual ABI type
  contractCode: string;
  bytecode: string;
};

// Define TokenFees type
type TokenFees = Record<number, Record<TokenType, TokenInfo>>;


export const Token_make_fees: TokenFees = {

  1: {
    [TokenType.BASIC]: {
      fees:9724126530334413,
      abi: TokenABI,
      contractCode: Basic_contract,
      bytecode:Token_make_bytecode[0]
     
    },
    [TokenType.TAX]: {
      fees: 9724126530334413,
  
      abi: TAXabi,
      contractCode: TAX_TOKEN,
      bytecode:Token_make_bytecode[1]     
    },
  },
  56: {
    [TokenType.BASIC]: {
      fees: 57607005011809440,
     
      abi: TokenABI,
      contractCode: Basic_contract,
      bytecode:Token_make_bytecode[0]
     
    },
    [TokenType.TAX]: {
      fees: 57607005011809440,
   
      abi: TAXabi,
      contractCode: TAX_TOKEN,
      bytecode:Token_make_bytecode[1]     
    },
  },
  97: {
    [TokenType.BASIC]: {
      fees: 5000000000,

      abi: TokenABI,
      contractCode: Basic_contract,
       bytecode:Token_make_bytecode[0]
    },
    [TokenType.TAX]: {
      fees: 5000000000,

      abi: TAXabi,
      contractCode: TAX_TOKEN,
      bytecode:Token_make_bytecode[1]    },
  },
  80001: {
    [TokenType.BASIC]: {
      fees: 5000000000,

      abi: TokenABI,
      contractCode: Basic_contract,
       bytecode:Token_make_bytecode[0]
    },
    [TokenType.TAX]: {
      fees: 5000000000,

      abi: TAXabi,
      contractCode: TAX_TOKEN,
      bytecode:Token_make_bytecode[1]    },
  },
  137: {
    [TokenType.BASIC]: {
      fees: 60157130424669230000,

      abi: TokenABI,
      contractCode: Basic_contract,
       bytecode:Token_make_bytecode[0]
    },
    [TokenType.TAX]: {
      fees: 60157130424669230000,

      abi: TAXabi,
      contractCode: TAX_TOKEN,
      bytecode:Token_make_bytecode[1]    },
  },
  42161:{
    [TokenType.BASIC]: {
      fees: 9724126530334413,

      abi: TokenABI,
      contractCode: Basic_contract,
       bytecode:Token_make_bytecode[0]
    },
    [TokenType.TAX]: {
      fees: 9724126530334413,

      abi: TAXabi,
      contractCode: TAX_TOKEN,
      bytecode:Token_make_bytecode[1]    },
  },
  10:{
    [TokenType.BASIC]: {
      fees: 9724126530334413,

      abi: TokenABI,
      contractCode: Basic_contract,
       bytecode:Token_make_bytecode[0]
    },
    [TokenType.TAX]: {
      fees: 9724126530334413,
  
      abi: TAXabi,
      contractCode: TAX_TOKEN,
      bytecode:Token_make_bytecode[1]    },
  },
  250:{
    [TokenType.BASIC]: {
      fees: 64082572530791680000,

      abi: TokenABI,
      contractCode: Basic_contract,
       bytecode:Token_make_bytecode[0]
    },
    [TokenType.TAX]: {
      fees: 64082572530791680000,
      abi: TAXabi,
      contractCode: TAX_TOKEN,
      bytecode:Token_make_bytecode[1]    },
  }
};

export interface DeployAPI_TYPE {
  [key: number]: {
    apiurl: string;
    router: string;
    chainurl:string
  };
}

export const Deploy_API: DeployAPI_TYPE = {
  1: {
    apiurl: "https://api.etherscan.io/api",
    router: "0x7a250d5630b4cf539739df2c5dacb4c659f2488d",
    chainurl:"https://etherscan.io/token/"
  },
  56: {
    apiurl: "https://api.bscscan.com/api",
    router: "0x10ED43C718714eb63d5aA57B78B54704E256024E",
    chainurl:"https://bscscan.com/token/"
  },
  80001: {
    apiurl: "https://api-testnet.polygonscan.com/api",
    router: "0xa5E0829CaCEd8fFDD4De3c43696c57F7D7A678ff",
    chainurl:"https://etherscan.io/token/"
  },
  97: {
    apiurl: "https://api-testnet.bscscan.com/api",
    router: "0xD99D1c33F9fC3444f8101754aBC46c52416550D1",
    chainurl:"https://etherscan.io/token/"
  },
  137: {
    apiurl: "https://api.polygonscan.com/api",
    router: "0xa5E0829CaCEd8fFDD4De3c43696c57F7D7A678ff",
    chainurl:"https://polygonscan.com/token/"
  },
  250:{
    apiurl: "https://api.ftmscan.com/api",
    router: "0xF491e7B69E4244ad4002BC14e878a34207E38c29",
    chainurl:"https://ftmscan.com/token/"
  },
  10:{
    apiurl: "https://api-optimistic.etherscan.io/api",
    router: "0xa5E0829CaCEd8fFDD4De3c43696c57F7D7A678ff",
    chainurl:"https://optimistic.etherscan.io/token/"
  },
  42161:{
    apiurl: "https://api.arbiscan.io/api",
    router: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
    chainurl:"https://arbiscan.io/token/"
  },
  43114:{
    apiurl: "https://api.routescan.io/v2/network/mainnet/evm/43114/etherscan/api",
    router: "0x60aE616a2155Ee3d9A68541Ba4544862310933d4",
    chainurl:"https://arbiscan.io/token/"
  }
};
